import React, { useEffect, useState } from 'react';
import "./style.css";
import arrow from '../../assets/icon_arrow.png';
import notification from '../../assets/icon_notification.png';
import notificationActive from '../../assets/icon_notification_active.png';
import notificationBing from '../../assets/icon_notification-bing.png';
import { TokenClaims } from '../../types/token-claims';
import { DecodeToken } from 'utils/decode-token';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/login/auth-service';
import { Avatar, Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import NotificationService from 'services/notification-service'; 
import { NotificationViewModel } from '../../types/models/notification-view-model';
import { ApiResponse } from '../../types/response/api-types';
import { AccessProfileEnum } from '../../types/enum/access-profile-enum';

function Navbar() {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [notifications, setNotifications] = useState<NotificationViewModel[]>([]); 
    const navigate = useNavigate();

    useEffect(() => {
        const token = AuthenticationService.GetToken();
        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
                fetchNotifications(decoded.accountid);
            }
        }
    }, []);

    const fetchNotifications = async (accountId: string) => {
        const response: ApiResponse<NotificationViewModel> = await NotificationService.GetNotificationsByAccountId(accountId);
        if (response.success) {
            setNotifications(response.data);
        } else {
            console.error(response.message);
        }
    };

    const handleRemoveNotifications = async (listNotificationsId: string[]) => {
        const result = await NotificationService.UpdateReadNotificationsByListId(listNotificationsId);

        if (result.success) {
            fetchNotifications(claims.accountid);
        }
    };

    const handleLogout = async () => {
        await AuthenticationService.Logout();
        navigate('/');
    };

    const handleItemClick = async (ticketId: string, notificationIds: string[]) => {
        const result = await NotificationService.UpdateReadNotificationsByListId(notificationIds);
        if (result.success && claims) {
            await fetchNotifications(claims.accountid);
        }

        if(claims?.profileid?.toLowerCase() === AccessProfileEnum.Operator.toLowerCase()){
            navigate(`/Manage?TicketId=${ticketId}`);
        }else if(claims?.profileid?.toLowerCase() === AccessProfileEnum.Administrator.toLowerCase()){
            navigate(`/Workspace?TicketId=${ticketId}`);
        }else{
            navigate(`/Tickets?TicketId=${ticketId}`);
        }
    };

    const userInitial = claims?.name ? claims.name.charAt(0).toUpperCase() : 'U';

    return (
        <nav className="navbar">
            <div className="navbar-content">
                <div className="container-user-info">
                    <div>
                        <Popover 
                            placement="bottom"
                            overlayClassName='container-popover-notifications'
                            content={(
                                <div className='container-main-notifications'>
                                    <div className='container-header-notifications'>
                                        <span>Suas Notificações</span>
                                    </div>
                                    <div className='container-content-notifications'>
                                        {notifications.length > 0 ? (
                                            notifications.map(notification => (
                                                <div key={notification.ids[0]} className='container-notification'>
                                                    <div className='notification-icon'>
                                                        <img src={notificationBing} alt="Ícone de Notificação" />
                                                    </div>
                                                    <div className='notification-message' onClick={notification.quantity === 0 ? () => handleItemClick(notification.ticketId, notification.ids) : undefined}>
                                                        {notification.quantity !== 0 && (
                                                            <span className='notification-message-title'>
                                                                {notification.quantity} <span className='notification-message-message'>{notification.title}</span> {notification.message}
                                                            </span>
                                                        )}

                                                        {notification.quantity === 0 && (
                                                            <span className='notification-message-title'><span className='notification-message-message'>{notification.title}</span> {notification.message}</span>
                                                        )}
                                                    </div>
                                                    <div className='notification-close-icon'>
                                                        <CloseOutlined onClick={() => handleRemoveNotifications(notification.ids)} />
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className='container-notification'>
                                                <span className='notification-message-message'>Nenhuma notificação disponível.</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}>
                            <img className='icon-notification' src={notifications.length > 0 ? notificationActive : notification} alt="Notificações" />
                        </Popover>
                    </div>
                    <Avatar className="user-photo">{userInitial}</Avatar>
                    <div className="user-details">
                        <p>{claims?.name || 'Usuário'}</p>
                        <p>{claims?.email || 'usuario@email.com'}</p>
                    </div>
                    <div className="dropdown">
                        <img className="dropdown-button" src={arrow} alt="Seta dropdown" />
                        <div className="dropdown-content">
                            <a href="#" onClick={handleLogout}>Sair</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
