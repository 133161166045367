import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "../../styles/global.css";
import Sidebar from 'components/sidebar';
import CustomButton from 'components/custom-button';
import InputWithLabel from 'components/custom-input-text-label';
import TextareaWithLabel from 'components/custom-textarea-label';
import ProfileTypeService from 'services/profile-type-service'; 
import { ProfileType } from '../../types/profile-type-types';
import { Checkbox } from 'antd';
import CustomNotification from 'components/custom-notification';
import Navbar from 'components/header';
import { Box, Spinner } from '@chakra-ui/react';

function CreateOrEditProfileType() {
    const [currentProfileType, setCurrentProfileType] = useState<ProfileType>({
        id: '00000000-0000-0000-0000-000000000000',
        name: '',
        description: '',
        status: true
    });
    const [isEditing, setIsEditing] = useState(false);
    const [errors, setErrors] = useState<{ name?: boolean, description?: boolean }>({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); 

    useEffect(() => {
        if (location.state && location.state.rowData) {
            const profileData = location.state.rowData as ProfileType;
            setCurrentProfileType(profileData);
            setIsEditing(true); 
        }
    }, [location.state]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCurrentProfileType(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const handleSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        const newErrors: { name?: boolean, description?: boolean } = {};

        if (!currentProfileType.name.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'O campo Nome é obrigatório.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.name = true;
            setErrors(newErrors);
        }
    
        if (!currentProfileType.description.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'O campo Descrição é obrigatório.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.description = true;
            setErrors(newErrors);
        }

        if(!currentProfileType.description.trim() || !currentProfileType.name.trim()){
            return;
        }

        let result;
        try {
            setLoading(true);
            if (isEditing) {
                result = await ProfileTypeService.UpdateProfileType(currentProfileType);
            } else {
                result = await ProfileTypeService.CreateProfileType(currentProfileType);
            }

            setLoading(false);
            if (result.success) {
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: isEditing ? "Tipo de perfil atualizado com sucesso!" : "Tipo de perfil criado com sucesso!",
                    notificationType: 'success',
                    placement: 'top-right'
                });
                navigate("/Workspace/ProfileTypes");
            } else {
                throw new Error(result.message || 'Erro inesperado.');
            }
        } catch (error) {
            setLoading(false);
            CustomNotification({
                title: 'Algo deu errado!',
                description: 'Erro ao processar a requisição.',
                notificationType: 'error',
                placement: 'top-right'
            });
        }
    }, [currentProfileType, isEditing, navigate]);

    return (
        <div className='container-crud'>
            <Sidebar />
            <Navbar />
            <div className='container-content-crud'>
                <div className='container-header-crud'>
                    <span>{isEditing ? "Editando Perfil" : "Criando Novo Perfil"}</span>
                    <div className='button-header-crud'>
                        <CustomButton variant='secondary' labelText='Voltar' onClick={handleBack} />
                    </div>
                </div>

                <div className='container-main-crud'>
                    {loading && (
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bg="rgba(255, 255, 255, 0.8)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            zIndex="10"
                        >
                            <Spinner size="xl" />
                        </Box>
                    )} 
                    <div className='container-title-crud'>
                        <span>Tipos de Perfis</span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='container-fields-crud'>
                            <div className='container-field-checkbox-crud'>
                                <Checkbox 
                                    checked={currentProfileType.status} 
                                    onChange={(e) => setCurrentProfileType(prev => ({ ...prev, status: e.target.checked }))} 
                                    className='container-custom-checkbox-input' 
                                />
                                <label>Ativo</label>
                            </div>

                            <div className='container-field-text-crud'>
                                <div className='field-text-name-crud'> 
                                    <InputWithLabel 
                                        label='Nome' 
                                        placeholder='Nome' 
                                        name="name"
                                        error={errors.name}
                                        maxLength={100}
                                        value={currentProfileType.name}
                                        onChange={handleInputChange} 
                                    />
                                </div>
                            </div>

                            <div className='container-textarea-crud'>
                                <TextareaWithLabel 
                                    label='Descrição' 
                                    placeholder='Escreva a Descrição do Sistema' 
                                    name="description"
                                    maxLength={250}
                                    error={errors.description}
                                    value={currentProfileType.description} 
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => 
                                        setCurrentProfileType(prev => ({ ...prev, description: e.target.value }))
                                    }
                                />
                            </div>
                            <div>
                                <CustomButton type="submit" variant='primary' labelText={isEditing ? 'Atualizar' : 'Confirmar'} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateOrEditProfileType;