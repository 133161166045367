import React from 'react';
import { Router } from 'react-router-dom';
import AppRoutes from './routes';
import "./styles/global.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <AppRoutes />       
    </div>
  );
}

export default App;
