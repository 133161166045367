import Config from "config";
import { ProfileType } from "../types/profile-type-types";
import { ApiResponse, PagedResult, PaginationDefaultRequest, PostApiResponse } from "../types/response/api-types";
import AuthenticationService from "./login/auth-service";

const API_URL = `${Config.API_URL}${Config.Endpoints.ProfileType}`;

const ProfileTypeService = {
    CreateProfileType: async(profileType: ProfileType): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                ProfileType: profileType
            };

            const response = await fetch(`${API_URL}/createprofiletype`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao criar tipo de perfil.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao criar tipo de perfil",
                data: false
            };
        }
    },
    UpdateProfileType: async(profileType: ProfileType): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                ProfileType: profileType
            };

            const response = await fetch(`${API_URL}/updateprofiletype`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao atualizar tipo de perfil.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao atualizar tipo de perfil",
                data: false
            };
        }
    },
    GetPagedProfileTypes: async (request: PaginationDefaultRequest): Promise<PostApiResponse<PagedResult<ProfileType>>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getpagedallprofiletypes`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify(request)
            });

            const data: PostApiResponse<PagedResult<ProfileType>> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os tipos de perfis.",
                    data: {} as  PagedResult<ProfileType>
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os tipos de perfis.",
                data: {} as  PagedResult<ProfileType> 
            };
        }
    },
    GetAllProfileTypes: async (): Promise<ApiResponse<ProfileType>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getallprofiletypes`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ SearchTerm: ""})
            });

            const data: ApiResponse<ProfileType> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os tipos de perfis.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os tipos de perfis.",
                data: [] 
            };
        }
    }
};

export default ProfileTypeService;
