import React, { useEffect, useRef, useState } from 'react';
import "./style.css";
import Sidebar from 'components/sidebar';
import Navbar from 'components/header';
import CustomButtonIcon from 'components/custom-button-icon';
import IconFilter from '../../assets/icon_filter.png';
import IconPdf from '../../assets/icon_pdf.png';
import ChartStacked from 'components/chart-stacked';
import ChartDoughnutFeedback from 'components/chart-doughnut-feedback';
import ChartDoughnutUser from 'components/chart-dougnut-user';
import ChartLine from 'components/chart-line';
import { Popover } from 'antd';
import CalendarDashboard from 'components/calendar-dashboard';
import { Module } from '../../types/module-types';
import { TicketType } from '../../types/ticket-type-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import ModuleService from 'services/module-service';
import TicketTypeService from 'services/ticket-type-service';
import AccountService from 'services/account-service';
import { TokenClaims } from '../../types/token-claims';
import AuthenticationService from 'services/login/auth-service';
import { DecodeToken } from 'utils/decode-token';
import AccessProfileService from 'services/access-profile-service';
import { AccessProfileViewModel } from '../../types/models/access-profile-view-model';
import TicketStatusService from 'services/ticket-status-service';
import { PriorityViewModel } from '../../types/models/priority-view-model';
import PriorityService from 'services/priority-service';
import { TicketStatusViewModel } from '../../types/models/ticket-status-view-model';
import moment from 'moment';
import { Box, Spinner } from '@chakra-ui/react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function Dashboard() {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [modules, setModules] = useState<Module[]>([]);
    const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
    const [operators, setOperators] = useState<AccountViewModel[]>([]);
    const [accessProfiles, setAccessProfiles] = useState<AccessProfileViewModel[]>([]);
    const [ticketStatusList, setTicketStatusList] = useState<TicketStatusViewModel[]>([]);
    const [priorities, setPriorities] = useState<PriorityViewModel[]>([]);
    const [accounts, setAccounts] = useState<AccountViewModel[]>([]);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [chartStackedKey, setChartStackedKey] = useState(0);
    const [chartDoughnutFeedbackKey, setChartDoughnutFeedbackKey] = useState(0);
    const [chartDoughnutUserkKey, setChartDoughnutUserkKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const printRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const token = AuthenticationService.GetToken();
        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (claims.accountid) {
                setLoading(true);

                try {
                    const [modulesResponse, ticketTypesResponse, operatorResponse, accessProfilesResponse, TicketStatusResponse, prioritiesResponse, accountResponse] = await Promise.all([
                        ModuleService.GetAllModules(claims.accountid),
                        TicketTypeService.GetAll(),
                        AccountService.GetAllOperatorsByAccountId(claims.accountid, claims.profileid),
                        AccessProfileService.GetAllAccessProfiles(),
                        TicketStatusService.GetAllTicketStatus(), 
                        PriorityService.GetAllPriorities(),
                        AccountService.GetAllAccountsForDashboard()
                    ]);
                    setModules(modulesResponse.data);
                    setTicketTypes(ticketTypesResponse.data);
                    setOperators(operatorResponse.data);
                    setAccessProfiles(accessProfilesResponse.data);
                    setTicketStatusList(TicketStatusResponse.data);
                    setPriorities(prioritiesResponse.data);
                    setAccounts(accountResponse.data);
                } catch (error) {
                    console.error("Error fetching data", error);
                }finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [claims]);

    const handleDateRangeSelected = (startDate: Date | undefined, endDate: Date | undefined) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setChartStackedKey(prevKey => prevKey + 1);
        setChartDoughnutFeedbackKey(prevKey => prevKey + 1);
        setChartDoughnutUserkKey(prevKey => prevKey + 1);
    };
  
    const handleExportPdf = async () => {
        const elements = document.querySelectorAll('.contatiner-chart-dashboard button, .container-header-chart-stacked, .container-header-info-filter-chart-line');

        elements.forEach(element => {
            (element as HTMLElement).style.display = 'none';
        });
    
        if (printRef.current) {           
            const canvas = await html2canvas(printRef.current);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();

            const title = "Dashboard do Administrador";
            pdf.setFontSize(18); 
            pdf.setTextColor(0, 0, 191);
            const pageWidth = pdf.internal.pageSize.width;
            const titleWidth = pdf.getTextWidth(title);
            const titleX = (pageWidth - titleWidth) / 2;
            pdf.text(title, titleX, 20);

            const imgWidth = 190;
            const pageHeight = pdf.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 25;

            pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('dashboard.pdf');
        }

        document.querySelectorAll('.container-header-chart-stacked, .container-header-info-filter-chart-line').forEach(element => {
            (element as HTMLElement).style.display = 'flex';
        });

        document.querySelectorAll('.contatiner-chart-dashboard button').forEach(element => {
            (element as HTMLElement).style.display = 'block';
        });
    };

    return(
        <div className='container-main-dashboard'>
            <Sidebar />
            <Navbar />
            <div className='container-content-dashboard'>
                <div className='container-title-dashboard'>
                    <span>Dashboard do Administrador</span>
                </div>
                <div className='container-sub-title-dashboard'>
                    <span>
                        Bem-vindo à área de Dashboards! Aqui você tem acesso a uma visão completa e atualizada dos principais indicadores e métricas, permitindo 
                        <br/> monitorar resultados, analisar tendências e tomar decisões informadas.      
                    </span>
                </div>
                <div className='container-actions-dashboard'>
                    {loading && (
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bg="rgba(255, 255, 255, 0.8)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            zIndex="10"
                        >
                            <Spinner size="xl" />
                        </Box>
                    )}
                    <div className='button-export-pdf'>
                        <CustomButtonIcon iconSrc={IconPdf} labelText='Exportar gráficos como PDF' variant='secondary' onClick={handleExportPdf} />
                    </div>

                    <Popover 
                        overlayClassName='container-calendar-dashboard'
                        placement="bottom"
                        content={<CalendarDashboard onDateRangeSelected={handleDateRangeSelected}/>}
                    >
                        <div className='button-filter-date'>
                            <CustomButtonIcon iconSrc={IconFilter} labelText='Filtrar por Data' variant='secondary' />
                        </div>                       
                    </Popover>
                </div>
                <div ref={printRef}>
                    <div  className='contatiner-chart-dashboard'>
                        <div className='container-chart-analytics-dashboard'>
                            <div className='label-chart-dashboard'>
                                <span>Quantidade de Chamados</span>
                            </div>
                            <div className='container-chart-analytics'>
                                <ChartStacked 
                                    key={chartStackedKey}
                                    modules={modules}
                                    ticketStatus={ticketStatusList}
                                    accessProfiles={accessProfiles}
                                    accounts={accounts}
                                    ticketTypes={ticketTypes}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </div>
                        </div>

                        <div className='container-chart-donut-dashboard'>
                            <div className='label-chart-dashboard'>
                                <span>Média de Avaliação</span>
                            </div>
                            <div>
                                <ChartDoughnutFeedback
                                    key={chartDoughnutFeedbackKey} 
                                    modules={modules}
                                    priorities={priorities}
                                    operators={operators}
                                    ticketStatus={ticketStatusList}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </div>
                        </div>

                        <div className='container-chart-donut-user-dashboard'>
                            <div className='container-sub-content-donut-user'>
                                <div className='label-chart-dashboard'>
                                    <span>Quantidade de Usuários no Sistema</span>
                                </div>
                                <ChartDoughnutUser
                                    key={chartDoughnutUserkKey} 
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </div>
                        </div>

                        <div className='container-chart-analytics-dashboard'>
                            <div className='label-chart-dashboard'>
                                <span>Tempo Médio de Conclusão por Chamado</span>
                            </div>
                            <div className='container-chart-analytics'>
                                <ChartLine 
                                    modules={modules}
                                    priorities={priorities}
                                    ticketTypes={ticketTypes}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;