import React, { useEffect, useState } from 'react';
import "./style.css";
import { TicketCommentary } from '../../types/ticket-commentary-types';
import moment from 'moment';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';
import { DetailsTicketViewModel } from '../../types/models/details-ticket-view-model';

interface PreviewCommentaryProps {
    ticketCommentaries: TicketCommentary[];
    ticketDetails: DetailsTicketViewModel;
}

const PreviewCommentary: React.FC<PreviewCommentaryProps> = ({ ticketCommentaries, ticketDetails }) => {
    const [commentaries, setCommentaries] = useState<any[]>([]);
    
    useEffect(() => {
        const combinedList = [
            ...ticketDetails.ticketCommentaries.map(commentary => ({
                type: 'commentary',
                id: commentary.id,
                authorName: commentary.authorName,
                createdOn: new Date(commentary.createdOn),
                observation: commentary.observation,
            })),
            ...ticketDetails.ticketAttachments.map(attachment => ({
                type: 'attachment',
                id: attachment.id,
                authorName: attachment.authorName,
                fileName: attachment.fileName,
                createdOn: new Date(attachment.createdOn),
            })),
            ...ticketDetails.ticketHistories.map(history => ({
                type: 'history',
                id: history.id,
                authorName: history.accountName,
                ticketHistoryTypeName: history.ticketHistoryTypeName,
                createdOn: new Date(history.createdOn),
            }))
        ].sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());

        setCommentaries(combinedList);
    }, [ticketDetails]);

    const formatDate = (date: Date): string => {
        return format(date, "dd/MM/yyyy ' - ' HH'h'mm", { locale: ptBR });
    };
    
    return(
        <div className='container-comments-details'>
            <div className='container-comments-title'>
                <span>Alterações e Observações</span>
            </div>

            <div className='container-content-comments'>
                {ticketCommentaries.slice().sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime()).map((commentary) => (
                    <ul key={commentary.id} className='container-comment-title'>
                        <li>
                        {`${commentary.authorName} - ${moment(commentary.createdOn).add(3, 'hours').format('HH[h]mm')}`}
                        <ul className='container-comment-description'>
                            <li>
                                {commentary.observation}
                            </li>
                        </ul>
                        </li>
                    </ul>
                ))}

                {commentaries.map(item => (
                    <ul key={item.id} className='container-comment-title'>
                        <li>
                            {item.type === 'commentary' ? (
                                    <>
                                        {`${item.authorName} - ${formatDate(item.createdOn)}`}
                                        <ul className='container-comment-description'>
                                            <li>{item.observation}</li>
                                        </ul>
                                    </>
                                ) : item.type === 'attachment' ? (
                                    <>
                                        {`${item.authorName} - ${formatDate(item.createdOn)}`}
                                        <ul className='container-comment-description'>
                                            <li>{`Adicionou um anexo`} <span className='comment-attachment-file-name'>{item.fileName}</span></li>
                                        </ul>
                                    </>
                                ) : item.type === 'history' && (
                                    <>
                                        {`${item.authorName} - ${formatDate(item.createdOn)}`}
                                        <ul className='container-comment-description'>
                                            <li>{item.ticketHistoryTypeName}</li>
                                        </ul>
                                    </>
                                )}
                        </li>
                    </ul>
                ))}

                <ul className='container-comment-title'>
                    <li>
                        {`${ticketDetails.requesterName} - ${ticketDetails.requestDate ? formatDate(ticketDetails.requestDate) : '--/--/-- - 00h00'}`}
                        <ul className='container-comment-description'>
                            <li>
                                <span>
                                    Criou o Chamado                                                       
                                </span>                     
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PreviewCommentary;
