import { notification } from 'antd';

interface CustomNotificationProps {
    title: string;
    description: string;
    placement: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
    notificationType?: 'warning' | 'success' | 'error' | 'info'; 
}

const CustomNotification = ({ title, description, placement, notificationType }:CustomNotificationProps) => {
    const placementMap: Record<string, 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft'> = {
        'top-right': 'topRight',
        'top-left': 'topLeft',
        'bottom-right': 'bottomRight',
        'bottom-left': 'bottomLeft',
    };

    notification[notificationType || 'info']({
        message: title,
        description: description,
        placement: placementMap[placement] || 'topRight', 
        showProgress: true,
        duration: 2
    });
};

export default CustomNotification;
