import React, { useState } from 'react';
import { Table } from 'antd';
import "./style.css";
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

interface CustomTableCrudProps {
    columns: any[];
    dataSource: any[]; 
    total: number; 
    loading: boolean;
    title: string;
    currentPage: number;
    pageSize: number;
    handleTableChange: (pagination: any) => void;
}

const CustomTableCrud: React.FC<CustomTableCrudProps> = ({ columns, dataSource, total, loading, currentPage, pageSize, title,  handleTableChange}) => {
    return (
        <div className='container-main-table-crud'>
            <Table
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                title={() => <span>{title}</span>}
                rowKey="id"
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    //showTotal: (total: number) => `Mostrando páginas ${currentPage} a ${Math.ceil(total / pageSize)} de ${total} total`,
                    locale: {
                        items_per_page: 'Itens por página',
                    }
                }}
                onChange={handleTableChange}
                locale={{
                    emptyText: 'Nenhum item foi encontrado.',
                }}
            />
        </div>
    );
};

export default CustomTableCrud;
