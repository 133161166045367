import React, { useEffect, useState } from 'react';
import "./style.css";
import { Box, Spinner } from '@chakra-ui/react';
import InputWithLabel from 'components/custom-input-text-label';
import TextareaWithLabel from 'components/custom-textarea-label';
import CustomButton from 'components/custom-button';
import Dropzone from 'components/dropzone';
import PreviewImage from 'components/preview-image';
import { TokenClaims } from '../../types/token-claims';
import { DetailsTicketViewModel } from '../../types/models/details-ticket-view-model';
import TicketService from 'services/ticket-service';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { TicketAttachment } from '../../types/ticket-attachment-types';
import { TicketCommentary } from '../../types/ticket-commentary-types';
import AuthenticationService from 'services/login/auth-service';
import { DecodeToken } from 'utils/decode-token';
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import { Rate } from 'antd';
import { UpdateTicketStatusRequest } from '../../types/response/api-types';
import moment from 'moment-timezone';
import TicketFeedbackService from 'services/ticket-feedback-service';
import { TicketFeedback } from '../../types/ticket-feedback-types';
import CustomNotification from 'components/custom-notification';
import { formatClassTicketStatus } from 'utils/ticket-status-helpers';
import PreviewCommentary from 'components/preview-commentary';

interface TicketDetailsProps {
    ticketId: string;
    onTicketUpdated: () => void;
    onCancel: () => void;
    setLoading: (loading: boolean) => void;
}

function TicketDetails({ ticketId, onTicketUpdated, onCancel, setLoading  }: TicketDetailsProps) {
    const [ticketDetails, setTicketDetails] = useState<DetailsTicketViewModel>({
        ticketId: '',
        ticketTypeId: '',
        requesterId: '',
        operatorId: '',
        ticketStatusId: '',
        priorityId: '',
        subject: '',
        detailedDescription: '',
        ticketNumber: '',
        requesterName: '',
        priorityName: '',
        priorityHexadecimal: '',
        moduleName: '',
        ticketTypeName: '',
        ticketStatusName: '',
        requestDate: null,
        closeDate: null,
        ticketCommentaries: [],
        ticketAttachments: [],
        ticketHistories: []
    });
    const [loading, setLocalLoading] = useState(false);
    const [canIsEditing, setCanIsEditing] = useState(false);
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [files, setFiles] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [ticketCommentaries, setTicketCommentaries] = useState<TicketCommentary[]>([]);
    const [newComment, setNewComment] = useState<string>('');
    const [rating, setRating] = useState<number>(0);
    const [commentFeedback, setCommentFeedback] = useState<string>('');

    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);
    
    useEffect(() => {
        fetchTicketDetails();
    }, [claims, ticketId]);

    const fetchTicketDetails = async () => {            
        if (ticketId) {
            setLocalLoading(true);             
    
            try {
                const response = await TicketService.GetDetailsTicketByTicketId(ticketId);
                
                if (response.success) {
                    if (response.data && response.data.ticketFeedback) {
                        setCommentFeedback(response.data.ticketFeedback.commentary);
                        setRating(response.data.ticketFeedback.scoreRate);
                    }
    
                    const requesterId = response.data?.requesterId;
                    const accountId = claims?.accountid;
                
                    if (requesterId && accountId) {
                        setCanIsEditing(requesterId.toLowerCase() === accountId.toLowerCase());
                    }
    
                    setTicketDetails(response.data);                   
                } else {
                    CustomNotification({
                        title: 'Algo deu errado!',
                        description: response.message || 'Erro ao processar a requisição.',
                        notificationType: 'error',
                        placement: 'top-right'
                    });
                }
            } catch (error) {
                CustomNotification({
                    title: 'Erro ao buscar detalhes do ticket!',
                    description: 'Erro inesperado ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            } finally {
                setLocalLoading(false);
            }
        }
    };
    
    const handleFilesChange = (files: File[]) => {
        setUploadedFiles(files); 
    };

    const handleAddFiles = (files: File[]) => {
        setUploadedFiles([...files, ...uploadedFiles]); 
    };

    const formatDate = (date: Date): string => {
        return format(date, "dd/MM/yyyy ' - ' HH'h'mm", { locale: ptBR });
    };

    const handleAddCommentary = () => {
        if (newComment.trim()) {
            const newTicketCommentary: TicketCommentary = {
                id: Math.random().toString(36).substr(2, 8),
                ticketId: ticketId,
                observation: newComment,
                authorId: claims.accountid,
                authorName: claims.name,
                createdOn: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
            };

            setTicketCommentaries([...ticketCommentaries, newTicketCommentary]);
            setNewComment('');
        }
    };
 
    const transformFilesToTicketAttachment = async (files: File[]): Promise<TicketAttachment[]> => {
        const ticketAttachments = await Promise.all(files.map(async (file) => {
            const fileContent = await fileToBase64(file);
            return {
                id: '00000000-0000-0000-0000-000000000000',
                ticketId: ticketId,
                authorId: claims.accountid,
                fileName: file.name,
                filePath: '',
                fileType: file.type,
                fileContent: fileContent,
                createdOn:  new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
            };
        }));

        return ticketAttachments;
    };

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                if (fileReader.result) {
                    const base64 = (fileReader.result as string).split(',')[1];
                    resolve(base64);
                } else {
                    reject(new Error('Failed to read file content'));
                }
            };
            fileReader.onerror = () => {
                reject(new Error('Error reading file'));
            };
            fileReader.readAsDataURL(file);
        });
    };

    const formatTitle = () => {
        return (ticketDetails?.ticketStatusId.toLowerCase() === TicketStatus.Encerrado.toLowerCase()) ? "Histórico do Chamado" : "Acompanhamento ";
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setLoading(true);
        setLocalLoading(true);
        try {
            const ticketAttachments = await transformFilesToTicketAttachment(uploadedFiles);

            const commentaries = ticketCommentaries.map(x => ({
                ...x,
                id: '00000000-0000-0000-0000-000000000000'
            }));

            if(commentaries.length === 0 && ticketAttachments.length === 0){
                CustomNotification({
                    title: 'Atenção!',
                    description: 'É necessário fazer alguma alteração para salvar.',
                    notificationType: 'warning',
                    placement: 'top-right'
                });

                return;
            }
            
            const result = await TicketService.UpdateTicketDetails(ticketId, claims?.accountid, commentaries, ticketAttachments);
    
            if (result.success) {
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: result.message || 'Chamado atualizado com sucesso.',
                    notificationType: 'success',
                    placement: 'top-right'
                });
                setUploadedFiles([]);
                setTicketCommentaries([]);
                await fetchTicketDetails();
                onTicketUpdated();
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: result.message || 'Ocorreu um erro ao processar sua solicitação.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Erro',
                description: 'Ocorreu um erro ao se conectar com o servidor.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLoading(false);
            setLocalLoading(false);
        }
    }

    const handleReopenTicket = async () => {
        const request: UpdateTicketStatusRequest = {
            AccountId: claims.accountid,
            TicketId: ticketDetails.ticketId,
            TicketStatusId: TicketStatus.Reaberto
        };
    
        setLoading(true);
        
        try {
            const response = await TicketService.UpdateTicketStatus(request);
            
            if (response.data) {
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: response.message || 'Chamado reaberto com sucesso.',
                    notificationType: 'success',
                    placement: 'top-right'
                });
                onTicketUpdated();
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: 'Ocorreu um erro ao processar sua solicitação.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Erro',
                description: 'Ocorreu um erro ao se conectar com o servidor.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLoading(false);        
        }
    };    

    const handleSendFeedback = async () => {
        setLoading(true);
    
        const ticketFeedback: TicketFeedback = {
            id: '00000000-0000-0000-0000-000000000000',
            ticketId: ticketId,
            authorId: claims.accountid,
            scoreRate: rating,
            commentary: commentFeedback,
        };
    
        try {
            const feedbackResponse = await TicketFeedbackService.CreateTicketFeedback(ticketFeedback);
    
            if (feedbackResponse.success) {
                await fetchTicketDetails();
                CustomNotification({
                    title: 'Sucesso',
                    description: 'Feedback enviado com sucesso.',
                    notificationType: 'success',
                    placement: 'top-right',
                });
            } else {
                console.error(feedbackResponse.message);
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: 'Erro inesperado ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right',
                });
            }
            
            onTicketUpdated();
        } catch (error) {
            CustomNotification({
                title: 'Algo deu errado!',
                description: 'Erro inesperado ao processar a requisição.',
                notificationType: 'error',
                placement: 'top-right',
            });
        } finally {
            setLoading(false);
        }
    };

    const formatTicketStatus = () => {
        return formatClassTicketStatus[ticketDetails.ticketStatusId.toUpperCase() as TicketStatus] || formatClassTicketStatus[TicketStatus.Registrado];
    };
    
    const canReopen = ticketDetails?.closeDate && moment().diff(moment(ticketDetails.closeDate), 'days') <= 7;

    return (
        <div className='container-main-ticket-details'>

            {ticketDetails?.ticketStatusId?.toLowerCase() === TicketStatus.Encerrado.toLowerCase() && (
                <div className='container-ticket-close'>
                    <div className='container-ticket-feedback'>
                        <div className='container-ticket-feedback-title'>
                            <span className='ticket-feedback-title'>Avaliação Pós-Chamado</span>
                            <span className='ticket-feedback-sub-title'>Esse chamado foi encerrado, por favor avalie o atendimento do operador!</span>
                        </div>

                        <div className='container-ticket-feedback-rate'>
                            <Rate className='ticket-feedback-rate'  
                                value={rating}
                                disabled={(ticketDetails.ticketFeedback !== null)}
                                onChange={setRating}
                            />
                        </div>

                        <div className='textarea-ticket-feedback'>
                            <TextareaWithLabel 
                                placeholder='Escreva sobre como foi o seu atendimento durante o chamado!' 
                                label='' 
                                disabled={(ticketDetails.ticketFeedback !== null)}
                                value={commentFeedback}
                                onChange={(e) => setCommentFeedback(e.target.value)} 
                            />
                        </div> 

                        {!ticketDetails?.ticketFeedback && (
                            <div className='button-ticket-feedback'>
                                <CustomButton labelText='Enviar avaliação e confirmar encerramento' variant='primary' onClick={handleSendFeedback} />
                            </div>                     
                        )}
                    </div>

                    {canReopen && (
                        <div className='container-reopen-ticket'>
                            <div className='button-reopen-ticket'>
                                <CustomButton labelText='Reabrir Chamado' variant='primary' onClick={handleReopenTicket} />
                            </div>
                            <span className='description-reopen-ticket'>
                                Reabra o chamado <span className='description-reopen-ticket-bold'>somente</span> no caso do problema não ter sido resolvido.<br/>
                                O período máximo de reabertura são <span className='description-reopen-ticket-bold'>7 dias</span>.
                            </span>
                        </div>
                    )}
                </div>
            )}
            <div className='container-title-details'>
                <span>{formatTitle()}</span>
            </div>
            <div className='container-sub-title-details'>
                <span>Assunto do Chamado</span>
            </div>

            <div className='container-form-ticket-details'>
                {loading && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        bg="rgba(255, 255, 255, 0.8)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        zIndex="10"
                    >
                        <Spinner size="xl" />
                    </Box>
                )}

                <form onSubmit={handleSubmit}>
                    <div className='container-fields-ticket-details'>
                        <div>
                            <InputWithLabel value={ticketDetails.ticketNumber} disabled={true} label='ID' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.requesterName} disabled={true} label='Solicitante' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.requestDate ? formatDate(new Date(ticketDetails.requestDate)) : '--/--/-- - 00h00'} disabled={true} label='Data/Hora da Solicitação' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.closeDate ? formatDate(new Date(ticketDetails.closeDate)) : '--/--/-- - 00h00'} disabled={true} label='Data/Hora do Encerramento' />
                        </div>
                    </div>

                    <div className='container-info-ticket-details'>
                        <div>
                            <div className='container-tag-details'>
                                <label>Prioridade</label>
                                <div style={{ backgroundColor: ticketDetails.priorityHexadecimal }}>
                                    {ticketDetails.priorityName}
                                </div>
                            </div>
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.moduleName} disabled={true} label='Módulo' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.ticketTypeName} disabled={true} label='Tipo' />
                        </div>
                        <div>
                            <label className='custom-label'>Status</label>
                            <div className='container-ticket-status'>
                                <span className="text-ticket-status">{ticketDetails.ticketStatusName}</span>
                                <div className={`dot-ticket-status ${formatTicketStatus()}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className='container-textarea-ticket-details'>
                        <div className='container-textarea-subject'>
                            <TextareaWithLabel 
                                placeholder='Nomeie como se fosse o título do chamado' 
                                label='Assunto' 
                                value={ticketDetails.subject}
                                disabled={true}
                            />
                        </div>
                        <div className='container-textarea-description-details'>
                            <TextareaWithLabel 
                                placeholder='Descrição do seu problema relatando ações e processos que levaram ao erro.' 
                                label='Descrição Detalhada' 
                                value={ticketDetails.detailedDescription}
                                disabled={true}
                            />
                        </div>
                        {(ticketDetails?.ticketStatusId?.toLowerCase() !== TicketStatus.Encerrado.toLowerCase() && canIsEditing)  && (
                            <div className='container-textarea-comment'>
                                <TextareaWithLabel 
                                    placeholder='Comentário' 
                                    label='Adicionar e Responder Observações' 
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                />
                                <div className='button-observation-ticket-details'>
                                    <CustomButton labelText='Adicionar Observação' variant='primary' onClick={handleAddCommentary} />
                                </div>
                            </div>
                        )}
                    </div>

                    <PreviewCommentary 
                        ticketDetails={ticketDetails} 
                        ticketCommentaries={ticketCommentaries} 
                    />

                    <div className='container-attachments-details'>
                        {(ticketDetails?.ticketStatusId?.toLowerCase() !== TicketStatus.Encerrado.toLowerCase() && canIsEditing) && (
                            <div>
                                <div className='container-attachments-title-details'>
                                    <span>Anexos</span>
                                    <p>Insira os anexos que demonstram o problema, comtemplem o código ou mensagem de erro.</p>
                                </div>
                                <Dropzone onFilesChange={handleAddFiles} />
                                <PreviewImage 
                                    subject={ticketDetails.subject}
                                    labelText='Anexos Inseridos' 
                                    onFilesChange={handleFilesChange} 
                                    files={uploadedFiles}
                                />
                            </div>
                        )}

                        <PreviewImage 
                            subject={ticketDetails.subject}
                            subtitle='Veja os anexos inseridos anteriormente:'
                            onFilesChange={handleFilesChange} 
                            existingAttachments={ticketDetails.ticketAttachments}
                            files={files}
                        />
                    </div>

                    {(ticketDetails?.ticketStatusId?.toLowerCase() !== TicketStatus.Encerrado.toLowerCase() && canIsEditing) && (
                        <div className='container-buttons-ticket-details'>
                            <div className='button-cancel-ticket-details'>
                                <CustomButton labelText='Fechar' variant='primary' onClick={() => onCancel()} />
                            </div>
                            <div className='button-confirm-ticket-details'>
                                <CustomButton labelText='Confirmar' variant='primary' type="submit" />
                            </div>
                        </div>                        
                    )}
                </form>
            </div>
        </div>
    );
}

export default TicketDetails;
