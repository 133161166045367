import React, { useEffect, useState } from 'react';
import "./style.css";
import { Modal } from 'react-bootstrap';
import CustomButton from 'components/custom-button';
import { TokenClaims } from '../../types/token-claims';
import AuthenticationService from 'services/login/auth-service';
import { DecodeToken } from 'utils/decode-token';
import Select from 'antd/es/select';
import { Module } from '../../types/module-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import { Box, Spinner } from '@chakra-ui/react';
import { TicketViewModel } from '../../types/models/ticket-view-model';
import { GetTicketsRespostRequest, RespostTicketsRequest } from '../../types/response/api-types';
import TicketService from 'services/ticket-service';
import CustomNotification from 'components/custom-notification';

interface RespostTicketProps {
    isOpen: boolean;
    modules: Module[];
    operators: AccountViewModel[];
    handleClose: () => void;
}

function RespostTicket({ isOpen, modules, operators, handleClose }: RespostTicketProps) {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [moduleId, setModuleId] = useState<string>('');
    const [operatorId, setOperatorId] = useState<string>('');
    const [ticketRepostShow, setTicketRepostShow] = useState(false);
    const [loading, setLocalLoading] = useState(false);
    const [tickets, setTickets] = useState<TicketViewModel[]>([]);
    const [selectedTickets, setSelectedTickets] = useState<string[]>(['']);
    
    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setTickets([]);
            setTicketRepostShow(false);
            setModuleId('');
            setOperatorId('');
            setSelectedTickets(['']);
        }
    }, [isOpen]);

    const { Option } = Select;

    const handlerModuleSelected = async (value: string) => {
        setModuleId(value);

        setSelectedTickets(['']); 

        const request: GetTicketsRespostRequest = {
            AccountId: claims.accountid,
            ProfileId: claims.profileid,
            SearchTerm: ''
        };

        setLocalLoading(true);
        try {
            const response = await TicketService.GetTicketsRespost(request);

            if (response.success) {
                setTicketRepostShow(true);
                setTickets(response.data);
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: response.message || 'Erro ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Erro inesperado!',
                description: 'Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLocalLoading(false); 
        }
    };

    const handleTicketSelect = (index: number, value: string) => {
        const newSelectedTickets = [...selectedTickets];
        newSelectedTickets[index] = value;
        setSelectedTickets(newSelectedTickets);
    };

    const handleAddTicketSelect = () => {
        if(selectedTickets.length < tickets.length){
            if (selectedTickets[selectedTickets.length - 1] !== '') {
                setSelectedTickets([...selectedTickets, '']); 
            }else{
                CustomNotification({
                    title: 'Atenção!',
                    description: 'É necessário selecionar um chamado antes de adicionar outro.',
                    notificationType: 'warning',
                    placement: 'top-right'
                });
            }
        }else{
            CustomNotification({
                title: 'Atenção!',
                description: 'A quantidade de chamados disponíveis já está listada.',
                notificationType: 'warning',
                placement: 'top-right'
            });
        }
    };

    const handleRemoveLastTicketSelect = () => {
        if (selectedTickets.length > 1) {
            const newSelectedTickets = selectedTickets.slice(0, -1);
            setSelectedTickets(newSelectedTickets);
        }
    };

    const handleSelectAllTickets = () => {
        const allTickets = tickets
            .filter(ticket => ticket.moduleId.toLowerCase() === moduleId.toLowerCase())
            .map(ticket => ticket.id);
        
        setSelectedTickets(allTickets);
    };

    const handleConfirmRepost = async (e: React.FormEvent) => {
        e.preventDefault();
    
        if (selectedTickets.length === 0 || selectedTickets[0] === '') {
            CustomNotification({
                title: 'Atenção!',
                description: 'Para prosseguir, selecione pelo menos um chamado para repassar.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            return;
        }
    
        if (!operatorId) {
            CustomNotification({
                title: 'Atenção!',
                description: 'Por favor, selecione um operador.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            return;
        }
    
        const request: RespostTicketsRequest = {
            OperatorId: operatorId,
            ListTicketId: selectedTickets.filter(ticket => ticket !== '')
        };
    
        setLocalLoading(true);
        try {
            const response = await TicketService.RespostTicketst(request);
    
            if (response.success) {
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: response.message || 'Chamados repassados com sucesso!',
                    notificationType: 'success',
                    placement: 'top-right'
                });
                handleClose();
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: response.message || 'Erro ao repassar os chamados.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Erro inesperado!',
                description: 'Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLocalLoading(false);
        }
    };      

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            className="container-main-respost-ticket"
            backdrop={loading ? 'static' : true}
            keyboard={!loading} 
        >
            <Modal.Body>
                {loading && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        bg="rgba(255, 255, 255, 0.8)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        zIndex="10"
                    >
                        <Spinner size="xl" />
                    </Box>
                )}
                <div className='container-content-respost-ticket'>
                    <form onSubmit={handleConfirmRepost}>
                        <div className='container-respost-ticket'>
                            <div>
                                <div className='container-respost-ticket-title'>
                                    <span>Repassar Chamados</span>
                                </div>

                                <div className='container-select-module-respost-ticket'>
                                    <Select
                                        placeholder='Selecione o módulo que deseja:'
                                        onChange={handlerModuleSelected}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {modules.map(option => (
                                            <Option key={option.id} value={option.id}>{option.name}</Option>
                                        ))}
                                    </Select>
                                </div>

                                {ticketRepostShow && (
                                    <div className='container-respost-tickets-selected'>
                                        <div className='container-respost-tickets-header'>
                                            <span>Chamados</span>
                                        </div>

                                        <div className='container-tickets-selected'>
                                            {selectedTickets.map((ticket, index) => (
                                                <div className='container-select-operator-respost-ticket' key={index}>
                                                    <Select
                                                        placeholder='Selecione o chamado que deseja repassar:'
                                                        onChange={(value) => handleTicketSelect(index, value)}
                                                        disabled={moduleId === ''}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        value={ticket === '' ? undefined : ticket}
                                                    >
                                                        {tickets.filter(ticket => ticket.moduleId.toLowerCase() === moduleId.toLowerCase()).map(option => (
                                                            <Option 
                                                                key={option.id} 
                                                                value={option.id}
                                                                disabled={selectedTickets.includes(option.id)}
                                                            >
                                                                {option.subject} - <span className='description-ticket-respost-bold'>Solicitante {option.requesterName}</span>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='respost-buttons-tickets-selected'>
                                            <div className='button-select-more-respost-ticket'>
                                                <CustomButton labelText='+ Selecionar mais um chamado' variant='primary' onClick={handleAddTicketSelect} />
                                            </div>
                                            {selectedTickets.length > 1 && (
                                                <div className='button-remove-last-ticket-respost'>
                                                    <CustomButton labelText='-  Remover último chamado' variant='primary' onClick={handleRemoveLastTicketSelect} />
                                                </div>
                                            )}

                                            {tickets.length > selectedTickets.length  && (
                                                <div className='button-select-all-tickets'>
                                                    <CustomButton labelText='Selecionar todos os chamados' variant='primary' onClick={handleSelectAllTickets} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div>
                                <div className='container-respost-ticket-title'>
                                    <span>Selecionar operador</span>
                                </div>

                                <div className='container-select-operator-respost-ticket'>
                                    <Select
                                        placeholder='Selecione o operador que ficará responsável pelos chamados acima:'
                                        onChange={(value) => setOperatorId(value)}
                                        disabled={moduleId.length === 0}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {operators.filter(operator => operator.accountAccessModules.some(access => access.moduleId === moduleId)).sort((a, b) => a.name.localeCompare(b.name)) .map(option => (
                                            <Option key={option.id} value={option.id}>{option.name}</Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className='container-buttons-respost-ticket'>
                                    <div className='button-cancel-respost-ticket'>
                                        <CustomButton labelText='Fechar' variant='primary' onClick={handleClose} />
                                    </div>
                                    <div className='button-confirm-respost-ticket'>
                                        <CustomButton labelText='Confirmar' variant='primary' type="submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RespostTicket;