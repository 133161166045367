import { TicketFeedback } from "../types/ticket-feedback-types";
import { PostApiResponse } from "../types/response/api-types";
import AuthenticationService from "./login/auth-service";
import Config from "config";

const API_URL = `${Config.API_URL}${Config.Endpoints.TicketFeedback}`;

const TicketFeedbackService = {
    CreateTicketFeedback: async(ticketFeedback: TicketFeedback): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                TicketFeedback: ticketFeedback
            };

            const response = await fetch(`${API_URL}/createticketfeedback`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao criar novo feedback para o chamado.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao criar novo feedback para o chamado.",
                data: false
            };
        }
    }
}

export default TicketFeedbackService;
