import React, { useCallback, useEffect, useState } from 'react';
import "./style.css";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions, ChartData } from 'chart.js';
import CustomButtonIcon from 'components/custom-button-icon';
import IconFilter from '../../assets/icon_filter.png';
import IconExcel from '../../assets/icon_excel.png';
import DashboardService from 'services/dashboard-service';
import { PriorityCount } from '../../types/priority-count-types';
import * as XLSX from 'xlsx';
import { format } from 'date-fns/format';
import { Popover } from 'antd';
import FilterDashboard from 'components/filter-dashboard';
import { Module } from '../../types/module-types';
import { TicketType } from '../../types/ticket-type-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import { AccessProfileViewModel } from '../../types/models/access-profile-view-model';
import { FilterDashboardState } from '../../types/filter-dashboard-state-types';
import { TicketStatusViewModel } from '../../types/models/ticket-status-view-model';
import { FilterDashboardQuery } from '../../types/response/api-types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ChartStackedProps {
    modules: Module[];
    ticketStatus: TicketStatusViewModel[];
    ticketTypes: TicketType[];
    accounts: AccountViewModel[];
    accessProfiles: AccessProfileViewModel[];
    startDate?: Date;
    endDate?: Date;
}

const ChartStacked: React.FC<ChartStackedProps> = ({ modules, ticketStatus, ticketTypes, accounts, accessProfiles, startDate, endDate }) => {
    const [priorities, setPriorities] = useState<PriorityCount[]>([]);
    const [filters, setFilters] = useState<FilterDashboardState>({} as FilterDashboardState);
    const [chartKey, setChartKey] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchProfileTypesDashboard = async (): Promise<void> => {
            
            const request: FilterDashboardQuery = {
                moduleId: filters.moduleId,
                ticketTypeId: filters.ticketTypeId,
                ticketStatusId: filters.ticketStatusId,
                accountId: filters.accountId,
                startDate: startDate,
                endDate: endDate
            };

            const response = await DashboardService.GetQuantityTicketsDashboard(request);
            
            if(response.success){
                setPriorities(response.data.filter(item => item.count > 0));
            }
        };

        fetchProfileTypesDashboard();
    }, [filters]);

    const labels = priorities.map(item => item.name); 
    const counts = priorities.map(item => item.count);
    const backgroundColors = priorities.map(item => item.hexadecimal);

    const data: ChartData<'bar'> = {
        labels: labels,
        datasets: [
            {
                label: 'Prioridades',
                data: counts.map(count => Math.max(count, 0.7)), 
                backgroundColor: backgroundColors,
                barThickness: 45,
                borderRadius: { topLeft: 2, topRight: 2 },
            }
        ]
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: { 
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const originalValue = counts[context.dataIndex];
                        return `${context.dataset.label}: ${originalValue}`;
                    }
                }
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                max:  Math.ceil((Math.max(...counts) + 30) / 10) * 10,
                grid: {
                    color: '#0000BF33',
                    lineWidth: 1,
                }, 
                ticks: {
                    stepSize: 10
                }
            },
        },
    };

    const exportToExcel = () => {
        const filteredData = priorities.map((row) => ({
            'Prioridade': row.name,
            Quantidade: row.count
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        const today = new Date();
        const formattedDate = format(today, 'dd-MM-yyyy');
        const filename = `Quantidade_Chamados_Workflow_Dashboard_${formattedDate}.xlsx`;
    
        XLSX.writeFile(workbook, filename);
    };

    const handleFiltersChange = (filters: FilterDashboardState) => {
        setVisible(false);
        setFilters(filters);
        setChartKey(prevKey => prevKey + 1);
    };

    const handleVisibleChange = useCallback((visible: boolean) => {
        setVisible(visible);
    }, []);

    const getModuleName = () => {
        const module = modules.find(x => x.id === filters.moduleId);
        return filters.moduleId ? (module?.name || 'Módulo não encontrado') : 'Todos os módulos';
    };

    const getAccountName = () => {
        const account = accounts.find(x => x.id === filters.accountId);
        return filters.accountId ? (account?.name || 'Operador não encontrado') : 'Todos os usuários';
    };

    
    return(
        <div className='container-chart-stacked'>
            <div className='container-header-chart-stacked'>
                <div className='container-buttons-chart-stacked'>
                    <div className='button-export-excel-chart'>
                        <CustomButtonIcon iconSrc={IconExcel} labelText='Exportar como Tabela Excel' variant='secondary' onClick={exportToExcel} />
                    </div>
                    <div className='button-filter-chart'>
                        <Popover 
                            overlayClassName='container-filter-dashboard'
                            placement='bottomRight'
                            open={visible}
                            onOpenChange={handleVisibleChange}
                            content={
                                <FilterDashboard 
                                onFiltersChange={handleFiltersChange}
                                    modules={modules}
                                    ticketStatus={ticketStatus}
                                    ticketTypes={ticketTypes}
                                    accounts={accounts}
                                    accessProfiles={accessProfiles}
                                    />
                                }
                                >
                            <div>
                                <CustomButtonIcon iconSrc={IconFilter} labelText='Filtros' variant='secondary' />
                            </div>
                        </Popover>
                    </div>
                </div>
                <div className='container-info-filter-chart-stacked'>
                    <span className='header-info-filter-chart-stacked'>Filtrador atualmente por:</span>
                    <span className='description-info-filter-chart-stacked'>Módulo: {getModuleName()}/Operador: {getAccountName()}</span>
                </div>
            </div>
            <div className='container-content-chart-stacked'>
                <Bar key={chartKey} options={options} data={data} />

                <div className='container-labels-chart-stacked'>
                    {priorities.map((item, index) => (
                        <div key={index} className='label-chart-stacked'>
                            <div className='dot-priority-chart-stacked' style={{ backgroundColor: item.hexadecimal }}></div>
                            <div className="label-priority-chart-stacked">
                                {item.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>           
        </div>
    );
}

export default ChartStacked;