import React, { FormEvent, useState } from 'react';
import './style.css';
import CustomButton from 'components/custom-button';
import CustomInputPassword from 'components/custom-input-password';
import AuthenticationService from 'services/login/auth-service';
import { Box, Spinner, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordFirstLoginResponse, PostApiResponse, RecoverPasswordResponse } from '../../types/response/api-types';
import { Modal } from 'react-bootstrap';
import CustomNotification from 'components/custom-notification';

interface RecoverPasswordProps {
    recoverId?: string;
    changePasswordId?: string;
    token?: string;
    isOpen: boolean;
    onClose: () => void;
}

const RecoverPassword: React.FC<RecoverPasswordProps> = ({ recoverId, changePasswordId, token, isOpen, onClose }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
    
        if (password !== confirmPassword) {
            setErrorMessage('As senhas não coincidem. Tente novamente.');
            return;
        }

        if(password.length < 3){
            CustomNotification({
                title: 'Atenção!',
                description: 'Senha inválida. A senha deve conter no mínimo 4 dígitos.',
                notificationType: 'warning',
                placement: 'top-right'
            });
           return; 
        }
    
        setLoading(true);
    
        try {
            let response: PostApiResponse<RecoverPasswordResponse> | PostApiResponse<ChangePasswordFirstLoginResponse> | undefined;
    
            if (recoverId) {
                response = await AuthenticationService.RecoverPassword(recoverId, password);
            } else if (changePasswordId) {
                response = await AuthenticationService.ChangePasswordFirstLogin(changePasswordId, password, ((token)? token : ''));
            }
    
            if(response){
                if (response.success) {
                    CustomNotification({
                        title: 'Ação concluída com sucesso!',
                        description: 'Senha alterada com sucesso!',
                        notificationType: 'success',
                        placement: 'top-right'
                    });
                    onClose();
                    navigate('/Login');
                } else {
                    CustomNotification({
                        title: 'Algo deu errado!',
                        description: response.message || 'Erro ao tentar alterar a senha.',
                        notificationType: 'error',
                        placement: 'top-right'
                    });
                }
            }
        } catch (error) {
            CustomNotification({
                title: 'Erro!',
                description: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLoading(false);
        }
    };

    const formatTitle = () => {
        return (recoverId)? 'Recuperação de Senha' : 'Bem-vindo! Por favor, altere sua senha'
    }

        
    return (
        <Modal 
            show={isOpen}
            onHide={onClose}
            className="container-modal-recover-password"
            backdrop={loading ? 'static' : true}
            keyboard={!loading} 
            centered 
        >
            <div className="container-recover-password">
                {loading && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        bg="rgba(255, 255, 255, 0.8)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        zIndex="10"
                    >
                        <Spinner size="xl" />
                    </Box>
                )}  
                <div className='title-recover-password'>
                    <span>{formatTitle()}</span>
                </div>
                <div className='description-recover-password'>
                    <span>Insira a nova senha e a confirmação para finalizar o processo de troca de senha.</span>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='container-fields-recover-password'>
                        <CustomInputPassword
                            label='Nova senha'
                            placeholder='Senha'
                            value={password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        />

                        <CustomInputPassword
                            label='Confirme sua nova senha'
                            placeholder='Senha'
                            value={confirmPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                        />
                    </div>

                    {errorMessage && (
                        <div className="error-message-recover-password">
                            <span>{errorMessage}</span>
                        </div>
                    )}

                    <div className='container-button-recover-password'>
                        <CustomButton
                            labelText='Confirmar nova senha'
                            variant='primary'
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default RecoverPassword;
