import { TicketStatusViewModel } from "../types/models/ticket-status-view-model";
import { ApiResponse } from "../types/response/api-types";
import Config from "config";
import AuthenticationService from "./login/auth-service";

const API_URL = `${Config.API_URL}${Config.Endpoints.TicketStatus}`;

const TicketStatusService = {
    GetAllTicketStatus: async (): Promise<ApiResponse<TicketStatusViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getallticketstatus`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                }
            });

            const data: ApiResponse<TicketStatusViewModel> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os status do chamados.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os status do chamados.",
                data: [] 
            };
        }
    }
}

export default TicketStatusService;
