import React, { ButtonHTMLAttributes } from 'react';
import { Button } from 'react-bootstrap';
import "./style.css";

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    labelText: string;
    iconSrc?: string;
    variant?: string;
}

const CustomButtonIcon: React.FC<CustomButtonProps> = ({ labelText, iconSrc, variant, ...rest }) => {
    return (
        <Button className={`custom-button-icon ${variant}`}  {...rest}>
            {iconSrc && <img src={iconSrc} className="button-icon" />}
            {labelText}
        </Button>
    );
}

export default CustomButtonIcon;
