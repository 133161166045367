import Config from "config";
import AuthenticationService from "./login/auth-service";
import { PostApiResponse } from "../types/response/api-types";
import { TicketAttachmentViewModel } from "../types/models/details-ticket-view-model";

const API_URL = `${Config.API_URL}${Config.Endpoints.TicketAttachment}`;

const TicketAttachmentService = {
    GetTicketAttachmentById: async (ticketAttachmentId: string): Promise<PostApiResponse<TicketAttachmentViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getticketattachmentbyid`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({Id: ticketAttachmentId}),
            });

            const data: PostApiResponse<TicketAttachmentViewModel> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter ao obter a imagem do chamado.",
                    data: {} as TicketAttachmentViewModel
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter ao obter a imagem do chamado.",
                data: {} as TicketAttachmentViewModel
            };
        }
    },
    GetDownloadImage: async (ticketAttachmentId: string): Promise<PostApiResponse<Blob>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getdownloadimage`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({Id: ticketAttachmentId}),
            });

            const data = await response.blob();

            if (response.ok) {
                return {
                    success: true,
                    message: "Imagem obtida com sucesso.",
                    data: data,
                };
            } else {
                return {
                    success: false,
                    message: "Falha ao obter ao obter a imagem do chamado.",
                    data: {} as Blob
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter ao obter a imagem do chamado.",
                data: {} as Blob
            };
        }
    }
}

export default TicketAttachmentService;