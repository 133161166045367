import React, { useState } from 'react';
import './style.css';
import keyIcon from '../../assets/icon_key.png'; 
import eyeIcon from '../../assets/icon_eye.png'; 
import eyeIconSlash from '../../assets/icon_eye.png';

interface CustomInputPasswordProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
}

const CustomInputPassword: React.FC<CustomInputPasswordProps> = ({ label, ...rest }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="container-input-password">
            <div className="container-password-label">
                <label htmlFor="custom-input-password" className="custom-input-password-label">{label}</label>
            </div>
            <div className="custom-input-password-wrapper">
                <img src={keyIcon} alt="Ícone de chave" className="key-icon" />
                <input
                    type={showPassword ? "text" : "password"}
                    id="custom-input-password" 
                    className="custom-input-password"
                    {...rest}
                />
                <img
                    src={showPassword ? eyeIconSlash : eyeIcon}
                    alt="Ícone de olho"
                    className="eye-icon"
                    onClick={togglePasswordVisibility}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        </div>
    );
};

export default CustomInputPassword;
