import React, { useState } from 'react';
import "./style.css";
import IconFilter from '../../assets/icon_filter.png';
import { Checkbox, Select } from 'antd';
import CustomButton from 'components/custom-button';
import { FilterDashboardState } from '../../types/filter-dashboard-state-types';
import { TicketType } from '../../types/ticket-type-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import { Module } from '../../types/module-types';
import { PriorityViewModel } from '../../types/models/priority-view-model';
import { AccessProfileViewModel } from '../../types/models/access-profile-view-model';
import { TicketStatusViewModel } from '../../types/models/ticket-status-view-model';

interface FilterDashboardProps {
    onFiltersChange: (filters: FilterDashboardState) => void;
    modules: Module[];
    ticketStatus?: TicketStatusViewModel[];
    ticketTypes?: TicketType[];
    operators?: AccountViewModel[];
    priorities?: PriorityViewModel[];
    accessProfiles?: AccessProfileViewModel[];
    accounts?: AccountViewModel[];
    periodField?: boolean;
}

const FilterDashboard: React.FC<FilterDashboardProps> = ({ onFiltersChange, modules, ticketStatus, accounts, ticketTypes, operators, priorities, accessProfiles, periodField }) => {
    const [filters, setFilters] = useState<FilterDashboardState>({
        ticketTypeId: undefined,
        moduleId: undefined,
        periodDays: 365,
        operatorId: undefined,
        accessProfileId: undefined,
        priorityId: undefined,
        ticketStatusId: undefined
    });
    const [ticketTypeChecked, setTicketTypeChecked] = useState(false);
    const [ticketStatusChecked, setTicketStatusChecked] = useState(false);
    const [priorityChecked, setPriorityChecked] = useState(false);
    const [accessProfileChecked, setAccessProfileChecked] = useState(false);
    const [moduleChecked, setModuleChecked] = useState(false);
    const [dateChecked, setDateChecked] = useState(true);
    const [operatorChecked, setOperatorChecked] = useState(false);
    const [accountChecked, setAccountChecked] = useState(false);

    const { Option } = Select;

    const handleSelectChange = (value: string | number, filterKey: keyof FilterDashboardState, setChecked: React.Dispatch<React.SetStateAction<boolean>>) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterKey]: value,
        }));
        setChecked(!!value);
    };

    const handleCancel = () => {
        const reset: FilterDashboardState = {
            ticketTypeId: undefined,
            moduleId: undefined,
            periodDays: undefined,
            operatorId: undefined,
            accessProfileId: undefined,
            priorityId: undefined,
            ticketStatusId: undefined,
            accountId: undefined
        };
        
        setFilters(reset);
        setTicketTypeChecked(false);
        setTicketStatusChecked(false);
        setPriorityChecked(false);
        setModuleChecked(false);
        setDateChecked(false);
        setOperatorChecked(false);
        setAccessProfileChecked(false);
        setAccountChecked(false);

        onFiltersChange(reset);
    };

    const applyFilters = () => {
        onFiltersChange(filters);
    };

    return(
        <div className='container-main-filter-dashboard'>
            <div className='container-title-filter-dashboard'>
                <img src={IconFilter} className="button-icon" alt="Filter Icon" />
                <span>Você deseja filtrar por:</span>
            </div>

            <div className='container-content-filter-dashboard'>
                <div className='container-selects-content-filter-dashboard'>
                    <div className='container-field-filter-dashboard'>
                        <Checkbox 
                            className='container-custom-checkbox-input'
                            checked={moduleChecked} 
                            onChange={() => {
                                const newChecked = !moduleChecked;
                                setModuleChecked(newChecked);
                                if (!newChecked) setFilters(prev => ({ ...prev, moduleId: undefined }));
                            }} 
                        />
                        <div className='field-select-crud'>
                            <label>Módulo</label>
                            <Select
                                placeholder='Selecionar Módulo'
                                value={filters.moduleId}
                                onChange={(value) => handleSelectChange(value, 'moduleId', setModuleChecked)}   
                            >
                                {modules.map(option => (
                                    <Option key={option.id} value={option.id}>{option.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    {ticketTypes && ( 
                        <div className='container-field-filter-dashboard'>
                            <Checkbox 
                                className='container-custom-checkbox-input'
                                checked={ticketTypeChecked} 
                                onChange={() => {
                                    const newChecked = !ticketTypeChecked;
                                    setTicketTypeChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, ticketTypeId: undefined }));
                                }} 
                            />
                            <div className='field-select-crud'>
                                <label>Tipo</label>
                                <Select
                                    placeholder='Selecionar Tipo'
                                    value={filters.ticketTypeId}
                                    onChange={(value) => handleSelectChange(value, 'ticketTypeId', setTicketTypeChecked)}   
                                >
                                    {ticketTypes.map(option => (
                                        <Option key={option.id} value={option.id}>{option.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    )}

                    {priorities && ( 
                        <div className='container-field-filter-dashboard'>
                            <Checkbox 
                                className='container-custom-checkbox-input'
                                checked={priorityChecked} 
                                onChange={() => {
                                    const newChecked = !priorityChecked;
                                    setPriorityChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, priorityId: undefined }));
                                }} 
                            />
                            <div className='field-select-crud'>
                                <label>Selecionar Prioridade</label>
                                <Select
                                    placeholder='Selecionar Prioridade'
                                    value={filters.priorityId}
                                    onChange={(value) => handleSelectChange(value, 'priorityId', setPriorityChecked)}    
                                >
                                    {priorities.map(option => (
                                        <Option key={option.id} value={option.id}>{option.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    )}

                    {accessProfiles && ( 
                        <div className='container-field-filter-dashboard'>
                            <Checkbox 
                                className='container-custom-checkbox-input'
                                checked={accessProfileChecked} 
                                onChange={() => {
                                    const newChecked = !accessProfileChecked;
                                    setAccessProfileChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, accessProfileId: undefined }));
                                }}  
                            />
                            <div className='field-select-crud'>
                                <label>Nível de Acesso</label>
                                <Select
                                    placeholder='Selecionar Nível de Acesso'
                                    value={filters.accessProfileId}
                                    onChange={(value) => handleSelectChange(value, 'accessProfileId', setAccessProfileChecked)}   
                                >
                                    {accessProfiles.map(option => (
                                        <Option key={option.id} value={option.id}>{option.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    )}

                    {periodField && ( 
                        <div className='container-field-filter-dashboard'>
                            <Checkbox 
                                className='container-custom-checkbox-input'
                                checked={dateChecked} 
                                onChange={() => {
                                    const newChecked = !dateChecked;
                                    setAccessProfileChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, periodDays: undefined }));
                                }} 
                            />
                            <div className='field-select-crud'>
                                <label>Janela de Tempo de Exibição</label>
                                <Select
                                    placeholder='Selecionar Janela de Tempo'
                                    value={filters.periodDays}
                                    onChange={(value) => handleSelectChange(value, 'periodDays', setDateChecked)}   
                                >
                                    <Option key={365} value={365}>Ano todo</Option>
                                    <Option key={180} value={180}>Últimos 6 meses</Option>
                                    <Option key={90} value={90}>Últimos 3 meses</Option>
                                </Select>
                            </div>
                        </div>  
                    )}

                    {operators && ( 
                        <div className='container-field-filter-dashboard'>
                            <Checkbox 
                                className='container-custom-checkbox-input'
                                checked={operatorChecked} 
                                onChange={() => {
                                    const newChecked = !operatorChecked;
                                    setOperatorChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, operatorId: undefined }));
                                }}  
                            />
                            <div className='field-select-crud'>
                                <label>Operador</label>
                                <Select
                                    placeholder='Selecionar Operador do Módulo'
                                    value={filters.operatorId}
                                    onChange={(value) => handleSelectChange(value, 'operatorId', setOperatorChecked)}     
                                >
                                    {operators.map(option => (
                                        <Option key={option.id} value={option.id}>{option.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    )}

                    {ticketStatus && ( 
                        <div className='container-field-filter-dashboard'>
                            <Checkbox 
                                className='container-custom-checkbox-input'
                                checked={ticketStatusChecked} 
                                onChange={() => {
                                    const newChecked = !ticketStatusChecked;
                                    setTicketStatusChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, ticketStatusId: undefined }));
                                }} 
                            />
                            <div className='field-select-crud'>
                                <label>Status</label>
                                <Select
                                    placeholder='Selecionar Status'
                                    value={filters.ticketStatusId}
                                    onChange={(value) => handleSelectChange(value, 'ticketStatusId', setTicketStatusChecked)}   
                                >
                                    {ticketStatus.map(option => (
                                        <Option key={option.id} value={option.id}>{option.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>             
                    )}
                </div>
            </div>
            <div className='container-footer-filter-dashboard'>
                <div className='sub-field-filter-dashboard'>
                    {accounts && ( 
                        <div className='container-field-filter-dashboard'>
                            <Checkbox 
                                className='container-custom-checkbox-input'
                                checked={accountChecked}
                                disabled={!filters.accessProfileId} 
                                onChange={() => {
                                    const newChecked = !accountChecked;
                                    setAccountChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, accountId: undefined }));
                                }}  
                            />
                            <div className='field-select-crud'>
                                <label>Selecione o Usuário</label>
                                <Select
                                    placeholder='Digite o Nome do Operador ou Usuário'
                                    value={filters.accountId}
                                    disabled={!filters.accessProfileId} 
                                    onChange={(value) => handleSelectChange(value, 'accountId', setAccountChecked)}   
                                >
                                    {accounts.filter(account => account.accessProfileId === filters.accessProfileId).sort((a, b) => a.name.localeCompare(b.name)).map(option => (
                                        <Option key={option.id} value={option.id}>{option.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    )}
                </div>
                <div className='container-buttons-content-filter-dashboard'>
                    <div className='button-cancel-filter-dashboard'>
                        <CustomButton labelText='Cancelar' variant='primary' onClick={handleCancel} />
                    </div>
                    <div className='button-apply-filter-dashboard'>
                        <CustomButton labelText='Aplicar Filtros' variant='primary' onClick={applyFilters}/>
                    </div>      
                </div>
            </div>
        </div>
    )
}

export default FilterDashboard;