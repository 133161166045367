import React, { useCallback, useEffect, useState } from 'react';
import "./style.css";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { ChartData, ChartOptions } from 'chart.js';
import CustomButtonIcon from 'components/custom-button-icon';
import IconFilter from '../../assets/icon_filter.png';
import IconExcel from '../../assets/icon_excel.png';
import { Popover, Rate } from 'antd';
import DashboardService from 'services/dashboard-service';
import { TicketFeedback } from '../../types/ticket-feedback-types';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { Module } from '../../types/module-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import { PriorityViewModel } from '../../types/models/priority-view-model';
import FilterDashboard from 'components/filter-dashboard';
import { FilterDashboardState } from '../../types/filter-dashboard-state-types';
import { TicketStatusViewModel } from '../../types/models/ticket-status-view-model';
import { FilterDashboardQuery } from '../../types/response/api-types';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

interface ChartDoughnutFeedbackProps {
    modules: Module[];
    ticketStatus: TicketStatusViewModel[];
    operators: AccountViewModel[];
    priorities: PriorityViewModel[];
    startDate?: Date;
    endDate?: Date;
}

const ChartDoughnutFeedback: React.FC<ChartDoughnutFeedbackProps> = ({ modules, ticketStatus, operators, priorities, startDate, endDate }) => {
    const [ticketFeedbacks, setTicketFeedbacks] = useState<TicketFeedback[]>([]);
    const [averageRate, setAverageRate] = useState<number>(0);
    const [totalFeedbacks, setTotalFeedbacks] = useState<number>(0);
    const [filters, setFilters] = useState<FilterDashboardState>({} as FilterDashboardState);
    const [chartKey, setChartKey] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchProfileTypesDashboard = async (): Promise<void> => {
            const request: FilterDashboardQuery = {
                moduleId: filters.moduleId,
                priorityId: filters.priorityId,
                ticketStatusId: filters.ticketStatusId,
                operatorId: filters.operatorId,
                startDate: startDate,
                endDate: endDate
            };
            
            const response = await DashboardService.GetTicketFeedbacksDashboard(request);
            
            if(response.success){              
                setTicketFeedbacks(response.data);
            } else {
                setTicketFeedbacks([]);
            }
        };

        fetchProfileTypesDashboard();
    }, [filters]);

    useEffect(() => {
        if (ticketFeedbacks.length > 0) {
            const totalRate = ticketFeedbacks.reduce((sum, feedback) => sum + feedback.scoreRate, 0);
            const average = totalRate / ticketFeedbacks.length;
            setAverageRate(average);
            setTotalFeedbacks(ticketFeedbacks.length);
        } else {
            setAverageRate(0);  
            setTotalFeedbacks(0);
        }
    }, [ticketFeedbacks]);

    const data: ChartData<'doughnut'> = {
        labels: [],
        datasets: [
            {
                label: 'Feedback',
                data: [averageRate, 5 - averageRate],
                backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
    
                    if (!chartArea || !isFinite(averageRate)) {
                        return context.dataIndex === 0 ? '#0D7AE5' : 'rgba(134, 189, 242, 0.5)';
                    }
    
                    if (context.dataIndex === 0) {
                        const filledGradient = ctx.createConicGradient(
                            Math.PI,
                            chartArea.left + chartArea.width / 2,
                            chartArea.top + chartArea.height / 2 
                        );
                        filledGradient.addColorStop(0, '#0D7AE5');
                        filledGradient.addColorStop(averageRate / 5, '#0D7AE5');
                        return filledGradient;
                    } else {
                        const unfilledGradient = ctx.createConicGradient(
                            Math.PI,
                            chartArea.left + chartArea.width / 2,
                            chartArea.top + chartArea.height / 2 
                        );
                        unfilledGradient.addColorStop(0, 'rgba(134, 189, 242, 0.5)');
                        unfilledGradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
                        return unfilledGradient;
                    }
                },
                borderWidth: 1,
            }
        ]
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        cutout: '82%',
        rotation: 180,
        plugins: {
            legend: {
                display: false
            },
            datalabels: { 
                display: false
            },
            tooltip: {
                enabled: false
            },
        },
    };

    const roundToHalf = (value: number): number => {
        return (Math.round(value * 2) / 2);
    };

    const exportToExcel = () => {
        const filteredData = [{
            "Média": roundToHalf(averageRate),
            'Total de Avaliações': totalFeedbacks
        }];
    
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        const today = new Date();
        const formattedDate = format(today, 'dd-MM-yyyy');
        const filename = `Media_Avaliacao_Workflow_Dashboard_${formattedDate}.xlsx`;
    
        XLSX.writeFile(workbook, filename);
    };

    const handleFiltersChange = (filters: FilterDashboardState) => {
        setVisible(false);
        setFilters(filters);
        setChartKey(prevKey => prevKey + 1);
    };
  
    const handleVisibleChange = useCallback((visible: boolean) => {
        setVisible(visible);
    }, []);
    
    return(
        <div className='container-chart-doughnut'>
            <div className='container-content-chart-doughnut'>
                <div className='container-info-doughnut-feedback'>
                    <span className='message-average-chart-feedback'>{roundToHalf(averageRate).toFixed(1)}/5</span>
                    <Rate className='chart-doughnut-feedback-rate'  
                        disabled={true}
                        allowHalf
                        value={roundToHalf(averageRate)}
                    />
                    <span className='message-total-chart-feedback'>{totalFeedbacks} Avaliações<br/>Totais</span>
                </div>

                <Doughnut
                    key={chartKey} 
                    data={data} 
                    options={options} 
                />
            </div>

            <div className='container-buttons-chart-doughnut'>
                <div className='button-export-excel-chart'>
                    <CustomButtonIcon iconSrc={IconExcel} labelText='Exportar como Tabela Excel' variant='secondary' onClick={exportToExcel} />
                </div>
                <Popover 
                    overlayClassName='container-filter-dashboard'
                    placement='bottomLeft'
                    open={visible}
                    onOpenChange={handleVisibleChange}
                    content={
                        <FilterDashboard 
                            onFiltersChange={handleFiltersChange}
                            modules={modules}
                            ticketStatus={ticketStatus}
                            operators={operators}
                            priorities={priorities}
                        />
                    }
                >
                    <div className='button-filter-chart'>
                        <CustomButtonIcon iconSrc={IconFilter} labelText='Filtros' variant='secondary' />
                    </div>
                </Popover>
            </div>
        </div>
    );
}

export default ChartDoughnutFeedback;