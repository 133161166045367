import { format } from "date-fns";
import { TicketViewModel } from "../types/models/ticket-view-model";
import * as XLSX from 'xlsx';
import { PriorityCount } from "../types/priority-count-types";
import { FilterState } from "../types/filter-state-types";
import { TicketStatus } from "../types/enum/ticket-status-enum-types";
import TicketService from "services/ticket-service";
import { ExportTicketsToExcelRequest } from "../types/response/api-types";

const formatOperatorName = (rowData: TicketViewModel) => {
    return rowData.operatorName ? rowData.operatorName : 'Não Atribuído';
};

export const ExportTicketsToExcel = async (listTicketStatus: TicketStatus[], filters?: FilterState, priority?: PriorityCount): Promise<void> => {

    const hasValidFilters = (filters: FilterState | undefined): boolean => {
        if (!filters) return false;
        return (Object.keys(filters) as Array<keyof FilterState>).some(key => filters[key] !== undefined);
    };

    const request: ExportTicketsToExcelRequest = {
        ListTicketStatusId: listTicketStatus,
        PriorityId: priority?.id,
        ...(filters && hasValidFilters(filters) ? { Filter: filters } : {})
    };

    try {
        const response = await TicketService.ExportTicketsToExcel(request);

        if (response.success && response.data.length > 0) {
            const filteredData = response.data.map((row) => ({
                'Data e Hora': (row.requestDate ? format(new Date(row.requestDate), "dd/MM/yyyy - HH'h'") : 'Data não disponível'),
                Tipo: row.ticketTypeName,
                Prioridade: row.priorityName,
                Solicitante: row.requesterName,
                'Operador Responsável': formatOperatorName(row),
                'Módulo': row.moduleName,
                Assunto: row.subject,
                'Descrição Detalhada': row.detailedDescription,
                Status: row.ticketStatusName
            }));

            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

            const today = new Date();
            const formattedDate = format(today, 'dd-MM-yyyy');
            const filename = `Chamados_Workflow_${formattedDate}.xlsx`;

            XLSX.writeFile(workbook, filename);

        } else {
            console.error("Falha ao obter os tickets ou nenhum ticket encontrado.");
        }
    } catch (error) {
        console.error("Erro ao exportar tickets para Excel:", error);
    }
};