import React from 'react';
import "./style.css";
import iconUser from '../../assets/icon_user.png'; 

interface CustomInputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
}

const CustomInputText: React.FC<CustomInputTextProps> = ({ label, ...rest }) => {
    return (
        <div className="custom-text-input-container">
            <label className="custom-input-text-label">{label}</label>
            <div className="custom-input-text-wrapper">
                <img src={iconUser} alt="Ícone de Usuário" className="icon-user" />
                <input {...rest} className="custom-input-text" />
            </div>
        </div>
    );
};

export default CustomInputText;
