import React, { ButtonHTMLAttributes } from 'react';
import { Button } from 'react-bootstrap';
import "./style.css";

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    labelText: string;
    variant?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ labelText, variant, ...rest }) => {
    return (
        <Button className={`custom-button ${variant}`}  {...rest}>{labelText}</Button>
    );
}

export default CustomButton;