import { ApiResponse } from "../types/response/api-types";
import { AccessProfileViewModel } from "../types/models/access-profile-view-model";
import AuthenticationService from "./login/auth-service";
import Config from "config";

const API_URL = `${Config.API_URL}${Config.Endpoints.AccessProfile}`;

const AccessProfileService = {
    GetAllAccessProfiles: async (): Promise<ApiResponse<AccessProfileViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getallaccessprofiles`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({SearchTerm: ''})
            });

            const data: ApiResponse<AccessProfileViewModel> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os perfis de acesso.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os perfis de acesso.",
                data: [] 
            };
        }
    }
}

export default AccessProfileService;