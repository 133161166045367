import Config from 'config';
import { ChangePasswordFirstLoginResponse, LoginResponse, PostApiResponse, RecoverPasswordResponse, ValidatePasswordResetTokenResponse } from '../../types/response/api-types';

const API_URL = `${Config.API_URL}${Config.Endpoints.Authentication}`;

const AuthenticationService = {
    Login: async (email: string, password: string): Promise<PostApiResponse<LoginResponse>> => {
        try {
            const response = await fetch(`${API_URL}/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });
    
            const data: PostApiResponse<LoginResponse> = await response.json();
    
            if (response.ok) {
                return data;
            } else {
                return {
                    success: false,
                    message: data.message || "Usuário ou senha inválido.",
                    data: {} as LoginResponse
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Ocorreu um erro durante o login. Por favor, tente novamente.",
                data: {} as LoginResponse
            };
        }
    },
    ForgotPassword: async (email: string): Promise<boolean> => {
        try {
            const response = await fetch(`${API_URL}/forgotpassword`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
    
            if (response.ok) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false; 
        }
    },
    ValidatePasswordRecoveryExpiration: async (passwordResetToken: string): Promise<PostApiResponse<ValidatePasswordResetTokenResponse>> => {
        try {
            const response = await fetch(`${API_URL}/validatepasswordrecoveryexpiration`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ passwordResetToken }),
            });
    
            const data: PostApiResponse<ValidatePasswordResetTokenResponse> = await response.json();
    
            if (!response.ok) {
                return {
                    success: false,
                    message: data.message || "Ocorreu um erro ao validar o token de recuperação. Por favor, tente novamente.",
                    data: {} as ValidatePasswordResetTokenResponse,
                };
            }
    
            return data;
    
        } catch (error) {
            console.error('Error in validatePasswordRecoveryExpiration:', error);
            return {
                success: false,
                message: "Ocorreu um erro ao validar o token de recuperação. Por favor, tente novamente.",
                data: {} as ValidatePasswordResetTokenResponse,
            };
        }
    },
    RecoverPassword: async (passwordResetToken: string, newPassword: string): Promise<PostApiResponse<RecoverPasswordResponse>> => {
        try {
            const response = await fetch(`${API_URL}/recoverpassword`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ passwordResetToken, newPassword }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data: PostApiResponse<RecoverPasswordResponse> = await response.json();

            if (response.ok) {
                return data;
            } else {
                return {
                    success: false,
                    message: data.message || "Ocorreu um erro durante o recuperação de senha. Por favor, tente novamente.",
                    data: {} as RecoverPasswordResponse
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Ocorreu um erro durante o recuperação de senha. Por favor, tente novamente.",
                data: {} as RecoverPasswordResponse
            };
        }
    },
    ValidateChangePasswordFirstLogin: async (accountPasswordManagementId: string): Promise<PostApiResponse<ValidatePasswordResetTokenResponse>> => {
        try {
            const response = await fetch(`${API_URL}/validatechangepasswordfirstlogin`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ AccountPasswordManagementId: accountPasswordManagementId }),
            });
    
            const data: PostApiResponse<ValidatePasswordResetTokenResponse> = await response.json();
    
            if (!response.ok) {
                return {
                    success: false,
                    message: data.message || "Ocorreu um erro ao validar a autorização para alterar senha. Por favor, tente novamente",
                    data: {} as ValidatePasswordResetTokenResponse,
                };
            }
    
            return data;
    
        } catch (error) {
            console.error('Error in validatePasswordRecoveryExpiration:', error);
            return {
                success: false,
                message: "Ocorreu um erro ao validar o token de recuperação. Por favor, tente novamente.",
                data: {} as ValidatePasswordResetTokenResponse,
            };
        }
    },
    ChangePasswordFirstLogin: async (accountPasswordManagementId: string, newPassword: string, token: string): Promise<PostApiResponse<ChangePasswordFirstLoginResponse>> => {
        try {
            const response = await fetch(`${API_URL}/changepasswordfirstlogin`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`   
                },
                body: JSON.stringify({AccountPasswordManagementId: accountPasswordManagementId, NewPassword: newPassword }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data: PostApiResponse<ChangePasswordFirstLoginResponse> = await response.json();

            if (response.ok) {
                return data;
            } else {
                return {
                    success: false,
                    message: data.message || "Ocorreu um erro durante a altereção de senha. Por favor, tente novamente.",
                    data: {} as ChangePasswordFirstLoginResponse
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Ocorreu um erro durante a altereção de senha. Por favor, tente novamente.",
                data: {} as ChangePasswordFirstLoginResponse
            };
        }
    },
    Logout: async (): Promise<void> => {
        localStorage.removeItem("workflow-platform-token-auth");
    },
    GetToken: (): string | null => {
        return localStorage.getItem("workflow-platform-token-auth");
    }
};

export default AuthenticationService;
