import React, { useEffect, useState } from 'react';
import "./style.css";
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/login/auth-service';
import IconHome from '../../assets/icon_home.png';
import IconLogoff from '../../assets/icon_logoff.png';
import IconDashboard from '../../assets/icon_dashboard.png';
import IconUsers from '../../assets/icon_users.png';
import IconPriority from '../../assets/icon_priority.png';
import IconModules from '../../assets/icon_modules.png';
import IconTypeProfile from '../../assets/icon_type_profile.png';
import { TokenClaims } from '../../types/token-claims';
import { UserRole } from '../../types/enum/user-role-types';
import { DecodeToken } from 'utils/decode-token';
import { Tooltip } from 'antd';

function Sidebar() {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    const handleLogout = async () => {
        await AuthenticationService.Logout();
        navigate('/');
    };

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    const getHomeRoute = () => {
        if (claims?.profile && claims.profile.toLowerCase() === UserRole.Administrator.toLowerCase()) {
            return '/Workspace';
        } else if (claims?.profile && claims.profile.toLowerCase() === UserRole.Operator.toLowerCase()) {
            return '/Manage';
        }
        return '/Tickets';
    };

    const isActive = (path: string) => location.pathname.startsWith(path);
    
    const homeIsActive = (path: string) => location.pathname === path;  

    return (
        <div className='container-sidebar'>
            <div className='container-sidebar-icon-top'>
                <Tooltip placement="rightBottom" title='Home'>
                    <div className={`icon-wrapper ${homeIsActive(getHomeRoute()) ? 'active-icon' : ''}`} onClick={() => handleNavigation(getHomeRoute())}>
                        <img className='home-icon' src={IconHome} alt="Ícone da home" />
                    </div>
                </Tooltip>
                {claims?.profile?.toLowerCase() === UserRole.Administrator.toLowerCase() && (
                    <Tooltip placement="rightBottom" title='Dashboard'>
                        <div className={`icon-wrapper ${isActive('/Workspace/Dashboard') ? 'active-icon' : ''}`} onClick={() => handleNavigation('/Workspace/Dashboard')}>
                            <img className='home-icon' src={IconDashboard} alt="Ícone de dashboard" />
                        </div>
                    </Tooltip>
                )}
            </div>

            {claims?.profile?.toLowerCase() === UserRole.Administrator.toLowerCase() && (
                <div className='container-sidebar-icon-main'>
                    <Tooltip placement="rightBottom" title='Usuários'>
                        <div className={`icon-wrapper ${isActive('/Workspace/Users') ? 'active-icon' : ''}`} onClick={() => handleNavigation('/Workspace/Users')}>
                            <img className='home-icon' src={IconUsers} alt="Ícone de usuários" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="rightBottom" title='Prioridades'>
                        <div className={`icon-wrapper ${isActive('/Workspace/Priorities') ? 'active-icon' : ''}`} onClick={() => handleNavigation('/Workspace/Priorities')}>
                            <img className='home-icon' src={IconPriority} alt="Ícone de prioridades" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="rightBottom" title='Módulos'>
                        <div className={`icon-wrapper ${isActive('/Workspace/Modules') ? 'active-icon' : ''}`} onClick={() => handleNavigation('/Workspace/Modules')}>
                            <img className='home-icon' src={IconModules} alt="Ícone módulos" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="rightBottom" title='Tipos de Perfis'>
                        <div className={`icon-wrapper ${isActive('/Workspace/ProfileTypes') ? 'active-icon' : ''}`} onClick={() => handleNavigation('/Workspace/ProfileTypes')}>
                            <img className='home-icon' src={IconTypeProfile} alt="Ícone de tipo de perfil" />
                        </div>
                    </Tooltip>
                </div>
            )}

            <div className='container-sidebar-icon-bottom'>
                <Tooltip placement="rightBottom" title='Sair'>
                    <div className='icon-wrapper'>
                        <img onClick={handleLogout} className='logoff-icon' src={IconLogoff} alt="Ícone de logoff" />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}

export default Sidebar;

