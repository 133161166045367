const Config = {
    API_URL: 'https://loving-cohen.65-181-111-22.plesk.page/api',
    Endpoints: {
        AccessProfile: '/AccessProfile',
        Authentication: '/Authentication',
        Account: '/Account',
        Color: '/Color',
        Dashboard: '/Dashboard',
        Module: '/Module',
        Notification: '/Notification',
        Priority: '/Priority',
        ProfileType: '/ProfileType',
        TicketFeedback: '/TicketFeedback',
        Ticket: '/Ticket',
        TicketType: '/TicketType',
        TicketStatus: '/TicketStatus',
        TicketAttachment: '/TicketAttachment'
    },
};
export default Config;