import { ApiResponse, PagedResult, PaginationDefaultRequest, PostApiResponse } from '../types/response/api-types';
import AuthenticationService from './login/auth-service';
import { Priority } from '../types/priority-types';
import { PriorityViewModel } from '../types/models/priority-view-model';
import Config from 'config';

const API_URL = `${Config.API_URL}${Config.Endpoints.Priority}`;

const PriorityService = {
    CreatePriority: async(priority: Priority, accountId: string): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                Priority: priority,
                CreatedAccountId: accountId
            };

            const response = await fetch(`${API_URL}/createpriority`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao criar uma nova prioridade.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao criar uma nova prioridade.",
                data: false
            };
        }
    },
    UpdatePriority: async(priority: Priority, accountId: string): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                Priority: priority,
                UpdatedAccountId: accountId
            };

            console.log(requestData)
            const response = await fetch(`${API_URL}/updatepriority`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao atualizar a prioridade.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao atualizar a prioridade",
                data: false
            };
        }
    },
    GetPagedPriorities: async (request: PaginationDefaultRequest): Promise<PostApiResponse<PagedResult<PriorityViewModel>>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getpagedpriorities`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify(request)
            });

            const data: PostApiResponse<PagedResult<PriorityViewModel>> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as prioridades.",
                    data: {} as  PagedResult<PriorityViewModel>
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as prioridades.",
                data: {} as  PagedResult<PriorityViewModel> 
            };
        }
    },
    GetAllPriorities: async (): Promise<ApiResponse<PriorityViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getallpriorities`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ SearchTerm: ""})
            });

            const data: ApiResponse<PriorityViewModel> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as prioridades.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as prioridades.",
                data: [] 
            };
        }
    }
};

export default PriorityService;
