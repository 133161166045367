import React, { useState } from 'react';
import "./style.css";
import { useDropzone, Accept } from 'react-dropzone';
import IconClips from '../../assets/icon_clips.png';

interface DropzoneProps {
  onFilesChange: (files: File[]) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onFilesChange }) => {

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] } as Accept,
    onDrop: (acceptedFiles) => {
      onFilesChange(acceptedFiles);
    },
  });

  return (
    <div className='container-dropzone-main'>
      <div {...getRootProps()} className='container-dropzone'>
        <input {...getInputProps()} />
        <div className='container-zone-items'>
          <img src={IconClips} className='clips-icon' alt="Icon Clips" />
          <span>Arraste ou Selecione o anexo que deseja inserir.</span>
        </div>
      </div>
    </div>
  );
};

export default Dropzone;
