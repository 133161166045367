import React, { useState } from 'react';
import "./style.css";
import iconSearch from '../../assets/icon_search.png'; 
import { Button } from 'react-bootstrap';
import { SearchOutlined } from '@ant-design/icons';

interface CustomInputSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onSearchClick: (searchValue: string) => void;
    onInputChange?: (inputValue: string) => void; 
}

const CustomInputSearch: React.FC<CustomInputSearchProps> = ({ onSearchClick, onInputChange, ...inputProps }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);

        if (onInputChange) {
            onInputChange(e.target.value);
        }
    };

    return (
        <div className="custom-input-search-container">
            <div className="custom-input-search-wrapper">
                <input 
                    placeholder='Buscar' 
                    className="custom-input-search"
                    value={inputValue}
                    onChange={handleInputChange} 
                    type='search'
                />
                <Button className='custom-input-search-button primary' onClick={() => onSearchClick(inputValue)}>
                    <SearchOutlined alt="Ícone de Busca" className="icon-search" />
                </Button>
            </div>
        </div>
    );
};

export default CustomInputSearch;
