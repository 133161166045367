import React, { useCallback, useEffect, useState } from 'react';
import "./style.css";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartData, ChartOptions } from 'chart.js';
import CustomButtonIcon from 'components/custom-button-icon';
import IconExcel from '../../assets/icon_excel.png';
import DashboardService from 'services/dashboard-service';
import { FilterDashboardQuery, GetProfileTypesDashboardResponse } from '../../types/response/api-types';
import * as XLSX from 'xlsx';
import { format } from 'date-fns/format';

ChartJS.register(ArcElement, Tooltip, Legend, Colors, ChartDataLabels);

interface ChartDoughnutUserProps {
    startDate?: Date;
    endDate?: Date;
}

const ChartDoughnutUser: React.FC<ChartDoughnutUserProps> = ({ startDate, endDate }) => {
    const [profileTypes, setProfileTypes] = useState<GetProfileTypesDashboardResponse[]>([]);

    useEffect(() => {
        const fetchProfileTypesDashboard = async (): Promise<void> => {
            const request: FilterDashboardQuery = {
                startDate: startDate,
                endDate: endDate
            };

            const response = await DashboardService.GetProfileTypesDashboard(request);
            
            if(response.success){
                setProfileTypes(response.data);
            }
        };

        fetchProfileTypesDashboard();
    }, []);

    const labels = profileTypes.map(profile => profile.profileTypeName);
    const dataValues = profileTypes.map(profile => profile.count);
    const backgroundColors = profileTypes.map(profile => profile.hexadecimal);
    const totalUsers = profileTypes.reduce((total, profile) => total + profile.count, 0);

    const data: ChartData<'doughnut'> = {
        labels: labels,
        datasets: [
            {
                label: 'Tipo de Perfil',
                data: dataValues,
                backgroundColor: backgroundColors, 
                borderWidth: 1,
            }
        ]
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        cutout: '60%',
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                position: 'nearest'
            },
            datalabels: { 
                color: '#FFFFFF',
                font: {
                    weight: 'bold',
                    size: 12
                },
                formatter: (value, context) => {
                    const percentage = ((value / totalUsers) * 100).toFixed(1);
                    const quantity = value;
                    return `${percentage}%\n   ${quantity}`;
                },
                anchor: 'center',
                align: 'center'
            },
        },
    };
 
    const exportToExcel = () => {
        const filteredData = profileTypes.map((row) => ({
            'Tipo de Perfil': row.profileTypeName,
            Quantidade: row.count,
            Porcentagem: ((row.count / totalUsers) * 100).toFixed(2) + '%' 
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        const today = new Date();
        const formattedDate = format(today, 'dd-MM-yyyy');
        const filename = `Quantidade_Usuarios_Workflow_Dashboard_${formattedDate}.xlsx`;
    
        XLSX.writeFile(workbook, filename);
    };

    return(
        <div className='container-chart-doughnut-user'>
            <div className='container-content-chart-doughnut-user'>
                <div className='container-info-doughnut'>
                    <span className='message-percentage-doughnut'>100%</span>
                    <span>{totalUsers} Usuários</span>
                </div>

                <Doughnut 
                    data={data} 
                    options={options} 
                />
            </div>
            <div className='container-description-doughnut-user'>
                <span>Níveis de Acesso/Perfis:</span>
                <div className="container-content-profile-types row">
                    {profileTypes.sort((a, b) => b.count - a.count).map((profile, index) => (
                        <div className="container-profile-type" key={index}>
                            <div className='dot-profile-type' style={{ backgroundColor: profile.hexadecimal }}></div>
                            <div className="label-profile-type">{profile.profileTypeName}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='container-buttons-chart-doughnut-user'>
                <div className='button-export-excel-chart'>
                    <CustomButtonIcon iconSrc={IconExcel} labelText='Exportar como Tabela Excel' variant='secondary' onClick={exportToExcel} />
                </div>
            </div>
        </div>
    );
}

export default ChartDoughnutUser;