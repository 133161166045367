import React, { useEffect, useState } from 'react';
import "./style.css";
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import { TokenClaims } from '../../types/token-claims';
import { DecodeToken } from 'utils/decode-token';
import AuthenticationService from 'services/login/auth-service';
import { GetByListTicketStatusIdRequest } from '../../types/response/api-types';
import TicketService from 'services/ticket-service';
import { TicketViewModel } from '../../types/models/ticket-view-model';
import { Skeleton, Table } from 'antd';
import CustomButton from 'components/custom-button';
import { FilterState } from '../../types/filter-state-types';

interface CustomTablePaginationButtonProps {
    columns: any[];
    listTicketStatus: TicketStatus[];
    filters?: FilterState;
    priorityId?: string;
}

const CustomTablePaginationButton: React.FC<CustomTablePaginationButtonProps> = ({ columns, filters, listTicketStatus, priorityId }) => {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [tickets, setTickets] = useState<TicketViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);
    const [totalRequest, setTotalRequest] = useState<number>(0);

    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    useEffect(() => {
        if (claims.accountid && listTicketStatus.length > 0) {
            const fetchTickets = async () => {
                setLoading(true);
            
                const hasValidFilters = (filters: FilterState | undefined): boolean => {
                    if (!filters) return false;
                    return (Object.keys(filters) as Array<keyof FilterState>).some(key => filters[key] !== undefined);
                };
            
                const requestBase: GetByListTicketStatusIdRequest = {
                    SearchTerm: '',
                    AccountId: claims.accountid,
                    PriorityId: priorityId,
                    ProfileId: claims.profileid,
                    Query: {
                        Page: currentPage,
                        PageSize: pageSize
                    },
                    ...(filters && hasValidFilters(filters) ? { Filter: filters } : {})
                };
            
                try {
                    let successfulRequestsCount = 0;
                    for (const option of tableOptions) {
                        const request = {
                            ...requestBase,
                            ListTicketStatusId: option.listStatus
                        };
            
                        const response = await TicketService.GetPagedTickets(request);

                        if (response.success) {
                            setTickets(prevTickets => [...prevTickets, ...response.data.items]);
                            if (totalRequest < 2) {
                                setTotal(prevTotal => prevTotal + response.data.count);
                                setTotalRequest(prevCount => prevCount + 1);
                                successfulRequestsCount++;
                            }
                        } else {
                            setError(response.message || 'Falha ao obter tickets.');
                        }
                    }
                } catch (error) {
                    setError('Ocorreu um erro ao buscar os tickets.');
                } finally {
                    setLoading(false);
                }
            };
            
            fetchTickets();
        }
    }, [claims.accountid, currentPage]);

    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const generateShortId = () => {
        return Math.random().toString(36).substr(2, 8);
    };

    const tableOptions = [
        { label: 'Abertos', listStatus: [TicketStatus.Registrado] },
        { label: 'Em Atendimento', listStatus: [TicketStatus.EmAndamento, TicketStatus.EsperandoAnexos, TicketStatus.Reaberto] }
    ];
    
    const groupedTickets = tableOptions.reduce((acc, option) => {
        acc[option.label] = [];
        const seenTickets = new Set<string>();
    
        option.listStatus.forEach(status => {
            const ticketsInStatus = tickets.filter(ticket => ticket.ticketStatusId.toLowerCase() === status.toLowerCase());
            
            ticketsInStatus.forEach(ticket => {
                if (!seenTickets.has(ticket.id)) { 
                    acc[option.label].push(ticket);
                    seenTickets.add(ticket.id);
                }
            });
        });
    
        return acc;
    }, {} as Record<string, TicketViewModel[]>);
    
    
    const components = {
        body: {
            wrapper: (props: any) => (
                <tbody {...props}>
                    {Object.keys(groupedTickets).map(groupLabel => (
                        <React.Fragment key={groupLabel}>
                            <tr>
                                <td className='container-description-table' colSpan={columns.length}>
                                    <div>
                                        Chamados: <span>{groupLabel}</span> 
                                    </div>
                                </td>
                            </tr>
                            {groupedTickets[groupLabel].length > 0 ? (
                                groupedTickets[groupLabel].sort((a, b) => new Date(b.requestDate).getTime() - new Date(a.requestDate).getTime()).map(ticket => (
                                    <tr key={ticket.id}>
                                        {columns.map(column => (
                                            <td key={column.key}>
                                                {column.render ? column.render(ticket) : ticket[column.dataIndex as keyof TicketViewModel]}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                        Nenhum chamado foi encontrado. 
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            ),
        },
    };

    return (
        <div className='container-table-pagination-button'>
            {loading ? (
                <Skeleton active paragraph={{ rows: (tickets.length * pageSize) }} />
            ) : (
                <div>
                    <Table
                        columns={columns}
                        dataSource={tickets}
                        rowKey={() => generateShortId()}
                        loading={loading}
                        pagination={false} 
                        components={components}
                        locale={{
                            emptyText: 'Nenhum chamado foi encontrado. Tente ajustar os filtros ou adicionar novos chamados.'
                        }}
                    />
                    {tickets.length < total && ( 
                        <div className='container-button-table-pagination'>
                            <CustomButton labelText='Carregar mais' variant="secondary" onClick={loadMore} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomTablePaginationButton;
