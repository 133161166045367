import React, { ChangeEvent } from 'react';
import "./style.css";

interface InputWithLabelProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean; 
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({ label, error, className, onChange, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e); 
    }
  };

  return (
    <div className="container-input-label">
      <label className="container-input-label">{label}</label>
      <input
        {...rest}
        className={`container-input-field ${error ? 'input-error' : ''}`}
        onChange={handleChange} 
      />
    </div>
  );
};

export default InputWithLabel;