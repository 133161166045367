import React, { ChangeEvent, TextareaHTMLAttributes } from 'react';
import "./style.css";

interface TextareaWithLabelProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error?: boolean; 
}

const TextareaWithLabel: React.FC<TextareaWithLabelProps> = ({ label, error, className, onChange, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="container-textarea">
      <label className="container-textarea-label">{label}</label>
      <textarea
        {...rest}
        className={`container-textarea-field ${error ? 'textarea-error' : ''}`}
        onChange={handleChange} 
      />
    </div>
  );
};

export default TextareaWithLabel;
