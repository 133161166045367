import React from 'react';
import Login from './pages/login/Index';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Tickets from 'pages/tickets';
import PublicRoute from 'components/route/public-route';
import PrivateRoute from 'components/route/private-route';
import { UserRole } from './types/enum/user-role-types';
import Workspace from 'pages/workspace';
import Users from 'pages/account';
import Modules from 'pages/module';
import Dashboard from 'pages/dashboard';
import Priorities from 'pages/priority';
import ProfileTypes from 'pages/profile-type';
import CreateOrEditProfileType from 'pages/profile-type/create-edit';
import CreateOrEditPriority from 'pages/priority/create-edit';
import CreateOrEditModule from 'pages/module/create-edit';
import CreateOrEditUser from 'pages/account/create-edit';
import ManageTickets from 'pages/manage';

function AppRoutes() {
    interface RouteProps {
        component: any;
        path: any;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/Login" />} />
                <Route path="/Login" element={<PublicRoute path="/Login" element={<Login />} />} />
                <Route path="/Workspace" element={<PrivateRoute element={<Workspace />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Manage" element={<PrivateRoute element={<ManageTickets />} allowedRoles={[UserRole.Operator]} />} />
                <Route path="/Tickets" element={<PrivateRoute element={<Tickets />} allowedRoles={[UserRole.User]} />} />
                <Route path="/Workspace/Dashboard" element={<PrivateRoute element={<Dashboard />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Users" element={<PrivateRoute element={<Users />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Users/Create" element={<PrivateRoute element={<CreateOrEditUser />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Users/Edit" element={<PrivateRoute element={<CreateOrEditUser />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Modules" element={<PrivateRoute element={<Modules />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Modules/Create" element={<PrivateRoute element={<CreateOrEditModule />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Modules/Edit" element={<PrivateRoute element={<CreateOrEditModule />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Priorities" element={<PrivateRoute element={<Priorities />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Priorities/Create" element={<PrivateRoute element={<CreateOrEditPriority />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/Priorities/Edit" element={<PrivateRoute element={<CreateOrEditPriority />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/ProfileTypes" element={<PrivateRoute element={<ProfileTypes />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/ProfileTypes/Create" element={<PrivateRoute element={<CreateOrEditProfileType />} allowedRoles={[UserRole.Administrator]} />} />
                <Route path="/Workspace/ProfileTypes/Edit" element={<PrivateRoute element={<CreateOrEditProfileType />} allowedRoles={[UserRole.Administrator]} />} />
            </Routes>
        </Router>       
    )
}


export default AppRoutes;