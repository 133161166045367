import React, { useCallback, useEffect, useState } from 'react';
import "./style.css";
import CustomButtonIcon from 'components/custom-button-icon';
import { Line } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import moment from 'moment';
import IconFilter from '../../assets/icon_filter.png';
import IconExcel from '../../assets/icon_excel.png';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';
import { ChartData, ChartOptions } from 'chart.js';
import DashboardService from 'services/dashboard-service';
import { FilterDashboardQuery, GetAverageTicketsCompletionDashboardResponse } from '../../types/response/api-types';
import { Module } from '../../types/module-types';
import { TicketType } from '../../types/ticket-type-types';
import { Popover } from 'antd';
import FilterDashboard from 'components/filter-dashboard';
import { FilterDashboardState } from '../../types/filter-dashboard-state-types';
import { PriorityViewModel } from '../../types/models/priority-view-model';
import { format } from 'date-fns';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

interface ChartLineProps {
    modules: Module[];
    priorities: PriorityViewModel[];
    ticketTypes: TicketType[];
}

const ChartLine: React.FC<ChartLineProps> = ({ modules, priorities, ticketTypes}) => {
    const [ticketAverages, setTicketAverages] = useState<GetAverageTicketsCompletionDashboardResponse[]>([]);
    const [filters, setFilters] = useState<FilterDashboardState>({
        periodDays: 365
    } as FilterDashboardState);
    const [startDate, setStartDate] = useState(moment);
    const [endDate, setEndDate] = useState(moment);
    const [chartKey, setChartKey] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchProfileTypesDashboard = async (): Promise<void> => {
            let calculatedStartDate = moment().startOf('month');
            let calculatedEndDate = moment().endOf('month');

            switch (filters.periodDays) {
                case 365:
                    calculatedStartDate = moment().startOf('year');
                    calculatedEndDate = moment().endOf('year');
                    break;
                case 180:
                    calculatedStartDate = moment().subtract(6, 'months').startOf('month');
                    calculatedEndDate = moment().endOf('month');
                    break;
                case 90:
                    calculatedStartDate = moment().subtract(3, 'months').startOf('month');
                    calculatedEndDate = moment().endOf('month');
                    break;
                default:
                    calculatedStartDate = moment().startOf('month');
                    calculatedEndDate = moment().endOf('month');
                    break;
            }

            setStartDate(calculatedStartDate);
            setEndDate(calculatedEndDate);

            var request: FilterDashboardQuery = {
                startDate: calculatedStartDate.toDate(),  
                endDate: calculatedEndDate.toDate(),
                moduleId: filters.moduleId,
                ticketTypeId: filters.ticketTypeId,
                priorityId: filters.priorityId
            };
            
            const response = await DashboardService.GetAverageTicketsCompletionDashboard(request);
            
            if(response.success){
                setTicketAverages(response.data.filter(item => item.monthlyAverages.some(average => average !== 0)));
            }
        };

        fetchProfileTypesDashboard();
    }, [filters]);
     
    const generateLabels = (start: moment.Moment, end: moment.Moment) => {
        const labels = [];
        const current = start.clone();
        
        while (current.isSameOrBefore(end, 'month')) {
            labels.push(current.format('MM/YY'));
            current.add(1, 'month');
        }

        return labels;
    };
    
    const labels = generateLabels(startDate, endDate);

    const dataSets = ticketAverages.map(ticket => ({
        label: ticket.name,
        data: ticket.monthlyAverages,
        borderColor: ticket.hexadecimal,
        backgroundColor: ticket.hexadecimal,
        borderWidth: 2,
        fill: true,
    }));

    const data: ChartData<'line'> = {
        labels: labels, 
        datasets: dataSets
    };

    const options: ChartOptions<'line'> = {
        responsive: false,
        plugins: {
            legend: {
                display: false 
            },
            datalabels: { 
                display: false
            }
        },
        scales: {
            x: {
                title: {
                    display: false
                },
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#0000BF',
                    font: {
                        size: 16,
                        weight: 'normal',
                    }
                },
            },
            y: {
                ticks: {
                    callback: function(value: number | string) {
                        const allowedValues = [7, 15, 30, 45, 60];
                        return allowedValues.includes(Number(value)) ? `${value} Dias` : null;
                    },
                    stepSize: 1,
                    color: '#0000BF',
                    font: {
                        size: 16,
                        weight: 'normal'
                    }
                },
                grid: {
                    color: '#0000BF33',
                    lineWidth: 2,
                },
                min: 0,
                max: 60,
                beginAtZero: true
            },
        },
    };

    const exportToExcel = () => {
        const worksheetData = [];
        const header = ['Mês', ...ticketAverages.map(ticket => ticket.name)];
        worksheetData.push(header);

        labels.forEach((label, index) => {
            const row = [label]; 
            ticketAverages.forEach(ticket => {
                row.push((ticket.monthlyAverages[index] || 0).toString());
            });
            worksheetData.push(row);
        });

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados do Gráfico');

        const today = new Date();
        const formattedDate = format(today, 'dd-MM-yyyy');
        const filename = `Tempo_Medio_Conclusao_Chamado_Workflow_Dashboard_${formattedDate}.xlsx`;
    
        XLSX.writeFile(workbook, filename);
    };

    const handleFiltersChange = (filters: FilterDashboardState) => {
        setVisible(false);
        setFilters(filters);
        setChartKey(prevKey => prevKey + 1);
    };

    const handleVisibleChange = useCallback((visible: boolean) => {
        setVisible(visible);
    }, []);

    const getModuleName = () => {
        const module = modules.find(x => x.id === filters.moduleId);
        return filters.moduleId ? (module?.name || 'Módulo não encontrado') : 'Todos os módulos';
    };

    const getPriorittyName = () => {
        const priority = priorities.find(x => x.id === filters.priorityId);
        return filters.priorityId ? (priority?.name || 'Prioridade não encontrado') : 'Todas as prioridades';
    };

    const getPeriodLabel = () => {
        switch (filters?.periodDays) {
            case 365:
                return 'Ano todo';
            case 180:
                return 'Últimos 6 meses';
            case 90:
                return 'Últimos 3 meses';
            default:
                return 'Período não encontrado';
        }
    };
    
    return (
        <div className='container-chart-line'>
            <div className='container-header-filter-chart-line'>
                <div className='container-info-header-filter-chart-line'>
                    <div className='button-export-excel-chart'>
                        <CustomButtonIcon iconSrc={IconExcel} labelText='Exportar como Tabela Excel' variant='secondary' onClick={exportToExcel} />
                    </div>
                    <Popover 
                        overlayClassName='container-filter-dashboard'
                        placement='bottomRight'
                        open={visible}
                        onOpenChange={handleVisibleChange}
                        content={
                            <FilterDashboard 
                                onFiltersChange={handleFiltersChange}
                                modules={modules}
                                priorities={priorities}
                                ticketTypes={ticketTypes}
                                periodField={true}
                            />
                        }
                    >
                        <div className='button-filter-chart'>
                            <CustomButtonIcon iconSrc={IconFilter} labelText='Filtros' variant='secondary' />
                        </div>
                    </Popover>
                </div>
                <div className='container-header-info-filter-chart-line'>
                    <span className='header-info-filter-chart-line'>Filtrador atualmente por: <span className='description-info-filter-chart-line'>{getModuleName()}/Prioridade: {getPriorittyName()}/ Data: {getPeriodLabel()}</span></span>
                </div>
            </div>
            <div className='container-content-chart-line'>
                <Line 
                    key={chartKey}
                    options={options} 
                    data={data} 
                    width={922}
                    height={461}
                />
            </div>           
        </div>
    );
}

export default ChartLine;
