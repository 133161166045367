import { TokenClaims } from '../types/token-claims';
import {jwtDecode} from 'jwt-decode';

export function DecodeToken(token: string): TokenClaims | null {
  try {
    const decoded = jwtDecode<TokenClaims>(token);
    return decoded;
  } catch (error) {
    console.error('Failed to decode token', error);
    return null;
  }
}
