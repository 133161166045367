import AuthenticationService from "./login/auth-service";
import { ApiResponse, PostApiResponse } from '../types/response/api-types';
import { NotificationViewModel } from "../types/models/notification-view-model";
import Config from "config";

const API_URL = `${Config.API_URL}${Config.Endpoints.Notification}`;

const NotificationService = {
    GetNotificationsByAccountId: async (accountId: string): Promise<ApiResponse<NotificationViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getnotificationsbyaccountid`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({AccountId: accountId})
            });

            const data: ApiResponse<NotificationViewModel> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha em obter as notificações.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha em obter as notificações.",
                data: [] 
            };
        }
    },
    UpdateReadNotificationsByListId: async(listNotificationsId: string[]): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();

            const response = await fetch(`${API_URL}/updatereadnotificationsbylistidasync`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify({ListNotificationsId: listNotificationsId}),
            });

            const data: PostApiResponse<boolean> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao atualizar a leitura da notificação.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao atualizar a leitura da notificação.",
                data: false
            };
        }
    },
    GetUpdatesByAccountId: async (accountId: string): Promise<ApiResponse<NotificationViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getupdatesbyaccountid`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({AccountId: accountId})
            });

            const data: ApiResponse<NotificationViewModel> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha em obter as notificações.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha em obter as notificações.",
                data: [] 
            };
        }
    },
}

export default NotificationService;
