import React, { useEffect, useState } from 'react';
import "./style.css";
import Sidebar from 'components/sidebar';
import Navbar from 'components/header';
import Menu from 'components/menu';
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import { PriorityCount } from '../../types/priority-count-types';
import Filter from 'components/filter';
import CustomButtonIcon from 'components/custom-button-icon';
import Popover from 'antd/es/popover';
import IconFilter from '../../assets/icon_filter.png';
import IconShared from '../../assets/icon_shared.png';
import IconDocument from '../../assets/icon_document.png';
import { DecodeToken } from 'utils/decode-token';
import AuthenticationService from 'services/login/auth-service';
import { TokenClaims } from '../../types/token-claims';
import { Module } from '../../types/module-types';
import { TicketType } from '../../types/ticket-type-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import { FilterState } from '../../types/filter-state-types';
import ModuleService from 'services/module-service';
import TicketTypeService from 'services/ticket-type-service';
import AccountService from 'services/account-service';
import CustomTablePaginationButton from 'components/custom-table-pagination-button';
import { Modal } from 'react-bootstrap';
import TicketManage from 'pages/ticket-manage';
import CustomTablePagination from 'components/custom-table-pagination';
import { TicketViewModel } from '../../types/models/ticket-view-model';
import editIcon from '../../assets/icon_edit.png'; 
import { format } from 'date-fns';
import RespostTicket from 'pages/respost-ticket';
import * as XLSX from 'xlsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatClassTicketStatus } from 'utils/ticket-status-helpers';
import { ExportTicketsToExcel } from 'utils/export-excel-tickets';

function Workspace() {
    const [listTicketStatus, setListTicketStatus] = useState<TicketStatus[]>([]);
    const [tickets, setTickets] = useState<TicketViewModel[]>([]);
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [priority, setPriority] = useState<PriorityCount | undefined>();
    const [modules, setModules] = useState<Module[]>([]);
    const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
    const [operators, setOperators] = useState<AccountViewModel[]>([]);
    const [filters, setFilters] = useState<FilterState>({} as FilterState);
    const [selectedTicketId, setSelectedTicketId] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableKey, setTableKey] = useState<number>(0);
    const [visible, setVisible] = useState(false);
    const [menuKey, setMenuKey] = useState<number>(0);
    const [modalRespostShow, setModalRespostShow] = useState(false);
    const listInProgress = [TicketStatus.Registrado,TicketStatus.EmAndamento, TicketStatus.EsperandoAnexos, TicketStatus.Reaberto];
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); 

    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (claims.accountid) {
            Promise.all([
                ModuleService.GetAllModules(claims.accountid),
                TicketTypeService.GetAll(),
                AccountService.GetAllOperatorsByAccountId(claims.accountid, claims.profileid)
            ]).then(([modulesResponse, ticketTypesResponse, accountResponse]) => {
                if (isMounted) {
                    setOperators(accountResponse.data)
                    setModules(modulesResponse.data);
                    setTicketTypes(ticketTypesResponse.data);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [claims]);

    const ticketId = searchParams.get('TicketId');

    useEffect(() => {
        if (ticketId) {
            setSelectedTicketId(ticketId);
            setModalShow(true);
        }
    }, [ticketId]);

    const handleClose = () => setModalShow(false);
    
    const handleShow = (ticketId: string) =>{
        setSelectedTicketId(ticketId);
        setModalShow(true);
    }; 

    const handleTicketCreated = () => {
        setTableKey(prevKey => prevKey + 1);
        setMenuKey(prevKey => prevKey + 1);
        handleClose();
    };

    const handleCancel = () => {
        navigate('/Workspace', { replace: true });
        handleClose();
    };

    const handleRespostClose = () => setModalRespostShow(false);

    const handleRespostShow = () =>{
        setModalRespostShow(true);
    }; 

    const handleLoadingStatus = (status: boolean) => {
        setIsLoading(status);
    };

    const handleUpdateListTicketStatus = (status: TicketStatus[]) => {
        setListTicketStatus(status);
    };
    
    const handleUpdatePriority = (priority?: PriorityCount) => {
        setPriority(priority);
    };

    const handleVisibleChange = (visible: boolean) => {
        setVisible(visible)
    };

    const handleFiltersChange = (filters: FilterState) => {
        setVisible(false);
        setFilters(filters);
        setTableKey(prevKey => prevKey + 1);
    };

    const handleTicketsUpdate = (listTickets: TicketViewModel[]) => {
        setTickets(prevTickets => {
            const uniqueTickets = new Map<string, TicketViewModel>();
            [...prevTickets, ...listTickets].forEach(ticket => {
                uniqueTickets.set(ticket.id, ticket);
            });
            return Array.from(uniqueTickets.values());
        });
    };

    const exportToExcel = () => {
        const listAllTicketStatus = new Set(Object.values(TicketStatus));
        
        const isAllTicketStatus = Array.from(listAllTicketStatus).every(status => listTicketStatus.includes(status));

        const ticketStatusList = isAllTicketStatus ? listInProgress : listTicketStatus;
        
        ExportTicketsToExcel(ticketStatusList, filters, priority);
    };

    const formateRequestDate = (rowData: TicketViewModel) => {
        const formattedDate = rowData.requestDate ? format(new Date(rowData.requestDate), "dd/MM/yyyy - HH'h'") : 'Data não disponível';
        return <div className='container-request-date'>{formattedDate}</div>;
    };

    const formatPriorityName = (rowData: TicketViewModel) => {
        if (rowData.priorityId) {
            return (
                <div className="container-tag-priority">
                    <div className='tag-priority' style={{ backgroundColor: rowData.priorityHexadecimal }}>{rowData.priorityName}</div>
                </div>
            );
        }
        return <div className="container-tag-priority"><div className='tag-priority tag-not-start'>{rowData.priorityName}</div></div>;
    };

    const formatOperatorName = (rowData: TicketViewModel) => {
        return rowData.operatorName ? rowData.operatorName : 'Não Atribuído';
    };

    const formatSubject = (rowData: TicketViewModel) => {
        return(
            <div className='container-subject-table'>
                <p className='table-text-content'>{rowData.subject}</p>
            </div>
        )
    };

    const formatDetailedDescription = (rowData: TicketViewModel) => {
        return (
            <div className='container-detailed-description-table'>
                <p className='table-text-content'>{rowData.detailedDescription}</p>
            </div>
        )
    };

    const formatTicketStatus = (rowData: TicketViewModel) => {
        return (
            <div className='container-ticket-status-table'>
                {rowData.ticketStatusName}
                <div className={`eclipse-ticket-status ${formatClassTicketStatus[rowData.ticketStatusId.toUpperCase() as TicketStatus] || formatClassTicketStatus[TicketStatus.Registrado]}`}></div>
            </div>
        );
    };

    const formatEditTicket = (rowData: TicketViewModel) => {
        return (
            <div className='container-preview-attachment'>
                <img src={editIcon} alt="Ícone de editar" onClick={() => handleShow(rowData.id)} />
            </div>
        );
    };

    const columns = [
        { title: 'ID', dataIndex: 'ticketNumber', key: 'ticketNumber' },
        { title: 'Data e Hora', render: (text: any) => formateRequestDate(text), key: 'requestDate' },
        { title: 'Tipo', dataIndex: 'ticketTypeName', key: 'ticketTypeName' },
        { title: 'Prioridade', render: (text: any) => formatPriorityName(text), key: 'priority' },
        { title: 'Solicitante', dataIndex: 'requesterName', key: 'requesterName' },
        { title: 'Operador Responsável', render: (text: any) => formatOperatorName(text), key: 'operator' },
        { title: 'Módulo', dataIndex: 'moduleName', key: 'moduleName' },
        { title: 'Assunto', render: (text: any) => formatSubject(text), key: 'subject' },
        { title: 'Descrição Detalhada', render: (text: any) => formatDetailedDescription(text), key: 'detailedDescription' },
        { title: 'Status', render: (text: any) => formatTicketStatus(text), key: 'status' },
        { title: 'Editar', render: (text: any) => formatEditTicket(text), key: 'edit' },
    ];

    const renderTable = () => {
        const listAllTicketStatus = new Set(Object.values(TicketStatus));
        
        const isAllTicketStatus = Array.from(listAllTicketStatus).every(status => listTicketStatus.includes(status));
            
        if (isAllTicketStatus) {
            return (    
                <CustomTablePaginationButton columns={columns} filters={filters} key={tableKey} listTicketStatus={listInProgress} />
            )   
        } else {
            return (
                <CustomTablePagination columns={columns} priority={priority} filters={filters} key={tableKey} listTicketStatus={listTicketStatus} />
            );
        }
    }; 

    return(
        <div className='container-main-workspace'>
            <Sidebar />
            <Navbar />
            <div className='container-content-workspace'>
                <Menu key={menuKey} onUpdatePriority={handleUpdatePriority} onUpdateListTicketStatus={handleUpdateListTicketStatus} />

                <div className='container-sub-content-workspace'>
                    <div className='container-button-workspace'>
                        <div className='container-buttons-workspace'>
                            <Popover 
                                overlayClassName='container-workspace-filter'
                                placement="bottomLeft"
                                content={<Filter modules={modules} ticketTypes={ticketTypes} operators={operators} onFiltersChange={handleFiltersChange} />}
                                open={visible}
                                onOpenChange={handleVisibleChange}
                            >
                                <div className='button-filter-workspace'>
                                    <CustomButtonIcon iconSrc={IconFilter} labelText='Filtros' variant='secondary' />
                                </div>
                            </Popover>
                            <div className='button-excel-workspace'>
                                <CustomButtonIcon iconSrc={IconDocument} labelText='Exportar chamados como Excel' variant='secondary'  onClick={exportToExcel} />
                            </div>
                        </div>                     
                        
                        <div className='button-repost-ticket-workspace'>
                            <CustomButtonIcon iconSrc={IconShared} labelText='Repassar Chamados' variant='secondary' onClick={handleRespostShow} />
                        </div>
                    </div>
                    <div className='container-title-workspace'>
                        <span>Meus Chamados</span>
                    </div>
                    <div className='container-table-content-workspace'>
                        { renderTable() }
                    </div>
                </div>
            </div>

            <Modal 
                show={modalShow}
                onHide={handleCancel}
                className="container-custom-modal"
                backdrop={isLoading ? 'static' : true}
                keyboard={!isLoading} 
            >
                <Modal.Body>
                    <TicketManage 
                        ticketId={selectedTicketId}
                        onTicketUpdated={handleTicketCreated} 
                        onCancel={handleCancel}
                        setLoading={handleLoadingStatus}
                    />
                </Modal.Body>
            </Modal>

            <RespostTicket 
                isOpen={modalRespostShow}
                modules={modules} 
                operators={operators}
                handleClose={handleRespostClose} 
            />
        </div>
    )
}

export default Workspace;
