import React, { useEffect, useState } from 'react';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import Sidebar from 'components/sidebar';
import Navbar from 'components/header';
import CustomButton from 'components/custom-button';
import CustomTableCrud from 'components/custom-table-crud';
import { Module } from '../../types/module-types';
import editIcon from '../../assets/icon_edit.png'; 
import ModuleService from 'services/module-service';
import { PaginationDefaultRequest } from '../../types/response/api-types';
import CustomNotification from 'components/custom-notification';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Modules: React.FC = () => {
    const [modules, setModules] = useState<Module[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchModules = async () => {
            setLoading(true);
            const request: PaginationDefaultRequest = {
                SearchTerm: '',
                Query: {
                    Page: currentPage,
                    PageSize: pageSize
                }
            };

            try {
                const response = await ModuleService.GetPagedModules(request);

                if (response.success) {
                    setModules(response.data.items);
                    setTotal(response.data.count);
                } else {
                    throw new Error(response.message || 'Falha ao obter módulos.');
                }
            } catch (error) {
                CustomNotification({
                    title: 'Erro!',
                    description: 'Falha ao obter módulos.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            } finally {
                setLoading(false);
            }
        };

        fetchModules();
    }, [currentPage, pageSize]);

    const handleCreate = () => {
        navigate('/Workspace/Modules/Create');
    };

    const formatCheckboxStatus = (rowData: Module) => (
        rowData.status ? <CheckOutlined className='crud-status-icon-check' /> : <CloseOutlined className='crud-status-icon-close' />
    );

    const handleClick = (rowData: Module) => {
        navigate('/Workspace/Modules/Edit', { state: { rowData } });
    };

    const formatEditModule = (rowData: Module) => (
        <div className='crud-icon-edit' onClick={() => handleClick(rowData)}>
            <img src={editIcon} alt="Ícone de editar" />
        </div>
    );

    const columns = [
        {
            title: 'Ativo',
            render: (rowData: Module) => formatCheckboxStatus(rowData),
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } })
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            onHeaderCell: () => ({ style: { width: '30%' } }),
            onCell: () => ({ style: { width: '30%' } })
        },
        {
            title: 'Descrição do Tipo de Módulo',
            dataIndex: 'description',
            onHeaderCell: () => ({ style: { width: '40%' } }),
            onCell: () => ({ style: { width: '40%' } })
        },
        {
            title: '',
            render: (rowData: Module) => formatEditModule(rowData),
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } })
        }
    ];    

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    return (
        <div className='container-main-module'>
            <Sidebar />
            <Navbar />
            <div className='container-content-module'>
                <div className='container-header-module'>
                    <span>Gerenciamento de Módulos</span>
                    <div className='button-header-module'>
                        <CustomButton variant='primary' labelText='Criar novo Módulo' onClick={handleCreate} />
                    </div>
                </div>

                {loading && <div>Carregando...</div>}
                {error && <div className="error-message">{error}</div>}

                <CustomTableCrud
                    columns={columns}
                    dataSource={modules}
                    total={total}
                    loading={loading}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    title='Módulos'
                    handleTableChange={handleTableChange}
                />
            </div>
        </div>
    );
}

export default Modules;