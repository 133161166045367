import React, { useState } from 'react';
import "./style.css";
import IconFilter from '../../assets/icon_filter.png';
import InputWithLabel from 'components/custom-input-text-label';
import CustomButton from 'components/custom-button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';
import CustomNotification from 'components/custom-notification';

interface CalendarDashboardProps {
    onDateRangeSelected: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

function CalendarDashboard({ onDateRangeSelected }: CalendarDashboardProps) {
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);

    const handlerDateChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start || undefined);
        setEndDate(end || undefined);
    };

    const handleCancel = () => {
        setStartDate(undefined);
        setEndDate(undefined);
        onDateRangeSelected(undefined, undefined);
    };

    const applyFilters = () => {
        if (startDate && endDate){
            onDateRangeSelected(startDate, endDate);
        }else{
            CustomNotification({
                title: 'Atenção!',
                description: 'Por favor, selecione um período de início e fim.',
                notificationType: 'warning',
                placement: 'top-right',
            });
        }
    };

    return (
        <div className='container-main-calendar-dashboard'>
            <div className='container-title-calendar-dashboard'>
                <img src={IconFilter} className="icon-filter-dashboard" />
                <span>Selecione o Intervalo da Data que deseja:</span>
            </div>
            <div className='container-content-calendar-dashboard'>
                <div className='container-calendar'>
                    <DatePicker
                        selected={startDate}
                        onChange={handlerDateChange}
                        focusSelectedMonth={true}
                        locale={ptBR}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        className='calendar-dashboard'
                        formatWeekDay={(day) => day.charAt(0).toUpperCase()}
                        inline
                    />
                </div>
                <div className='container-inputs-calendar-dashboard'>
                    <div>
                        <InputWithLabel 
                            value={startDate ? startDate.toLocaleDateString('pt-BR') : '00/00/0000'} 
                            disabled={true} 
                            label='Data Inicial:' 
                        />
                        <InputWithLabel 
                            value={endDate ? endDate.toLocaleDateString('pt-BR') : '00/00/0000'} 
                            disabled={true} 
                            label='Data Final:' 
                        />
                    </div>
                    <div className='container-buttons-calendar-dashboard'>
                        <div className='button-apply-calendar-dashboard'>
                            <CustomButton 
                                labelText='Aplicar Filtros' 
                                variant='primary' 
                                onClick={applyFilters}
                            />
                        </div>
                        <div className='button-cancel-calendar-dashboard'>
                            <CustomButton 
                                labelText='Cancelar' 
                                variant='primary' 
                                onClick={handleCancel} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarDashboard;