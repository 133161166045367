import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Image, useDisclosure, Box, Spinner, CloseButton } from "@chakra-ui/react";
import IconTrash from '../../assets/icon_trash.png';
import IconDownload from '../../assets/icon_download.png';
import IconPreview from '../../assets/icon_preview.png';
import "./style.css";
import { TicketAttachmentViewModel } from '../../types/models/details-ticket-view-model';
import CustomNotification from 'components/custom-notification';
import TicketAttachmentService from 'services/ticket-attachment-service';

interface PreviewImageProps {
    onFilesChange: (files: File[]) => void;
    labelText?: string;
    files: File[]; 
    subject: string;
    subtitle?: string;
    existingAttachments?: TicketAttachmentViewModel[];
}

const PreviewImage: React.FC<PreviewImageProps> = ({ onFilesChange, labelText, files, subject, subtitle, existingAttachments }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const formatFileSize = (size: number): string => {
        const kb = size / 1024;
        const mb = kb / 1024;
        return mb > 1 ? `${mb.toFixed(2)} MB` : `${kb.toFixed(2)} KB`;
    };

    const handleRemoveFile = (fileId: string) => {
        const updatedFiles = files.filter((file, index) => index !== parseInt(fileId));
        onFilesChange(updatedFiles);
    };
    
    const handleDownloadFile = async (file: File | TicketAttachmentViewModel) => {
        if ('filePath' in file) {
            setLoading(true);
            try {
                const response = await TicketAttachmentService.GetDownloadImage(file.id);
                
                if (response.success) {
                    const url = window.URL.createObjectURL(response.data);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = file.fileName
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                } else {
                    throw new Error(response.message || 'Falha ao obter a imagem.');
                }
            } catch (error) {
                CustomNotification({
                    title: 'Erro!',
                    description: 'Falha ao obter imagem.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            } finally {
                setLoading(false);
            }
        } else {
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            a.click();
            URL.revokeObjectURL(url);
        }
    };

    const handlePreviewImage = async (file: File | TicketAttachmentViewModel) => {
        onOpen();
        if ('filePath' in file) {
            setLoading(true);
            try {
                const response = await TicketAttachmentService.GetTicketAttachmentById(file.id);

                if (response.success) {
                    setPreviewUrl(response.data.filePath);
                } else {
                    throw new Error(response.message || 'Falha ao obter a imagem.');
                }
            } catch (error) {
                CustomNotification({
                    title: 'Erro!',
                    description: 'Falha ao obter imagem.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            } finally {
                setLoading(false);
            }
        } else {
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        }
    };

    return (
        <div className='container-dropzone-attachments'>
            {labelText && (
                <div className="dropzone-title-attachments">
                    <span>{labelText}</span>
                </div>
            )}
            {subtitle && (
                <div className="dropzone-sub-title-attachments">
                    <span>{subtitle}</span>
                </div>
            )}

            <div className='dropzone-attachments'>
                {(files.length > 0 || (existingAttachments && existingAttachments.length > 0)) ? (
                    <>
                        {files.map((file, index) => (
                            <div key={index} className='attachment-item'>
                                <div>
                                    <span className='attachment-file-name'>{file.name}</span>
                                    <span className='attachment-file-size'>{formatFileSize(file.size)}</span>
                                </div>
                                <div className='attachment-actions'>
                                    <img
                                        src={IconPreview}
                                        alt="Preview"
                                        onClick={() => handlePreviewImage(file)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <img
                                        src={IconDownload}
                                        alt="Download"
                                        onClick={() => handleDownloadFile(file)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <img
                                        src={IconTrash}
                                        alt="Remove"
                                        onClick={() => handleRemoveFile(index.toString())}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        ))}

                        {existingAttachments?.map((attachment) => (
                            <div key={attachment.id} className='attachment-item'>
                                <div>
                                    <span className='attachment-file-name'>{attachment.fileName}</span>
                                    <span className='attachment-file-size'></span>
                                </div>
                                <div className='attachment-actions'>
                                    <img
                                        src={IconPreview}
                                        alt="Preview"
                                        onClick={() => handlePreviewImage(attachment)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <img
                                        src={IconDownload}
                                        alt="Download"
                                        onClick={() => handleDownloadFile(attachment)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <span className='no-files-text'>Nenhum arquivo foi anexado até o momento.</span>
                )}
            </div>

            <Modal 
                isOpen={isOpen} 
                onClose={onClose} 
                isCentered
                closeOnOverlayClick={true}
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent className='container-modal-content-preview'>
                    <ModalHeader>
                        <div className='container-header-preview'>
                            <span className='modal-title-preview'>{subject ? subject : 'Novo chamado'}</span>
                            <span className='modal-sub-title-preview'>Visualização rápida de anexos</span>
                        </div>
                        <CloseButton onClick={onClose} />
                    </ModalHeader>
                    <ModalBody>
                    <div className="image-container">
                        {loading && (
                            <Box
                                position="absolute"
                                top="0"
                                left="0"
                                width="100%"
                                height="100%"
                                bg="rgba(255, 255, 255, 0.8)"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                zIndex="10"
                            >
                                <Spinner size="xl" />
                            </Box>
                        )} 
                        {previewUrl && (
                            <Image
                                src={previewUrl}
                                alt="Preview"
                                objectFit="contain"
                                maxWidth="100%"
                                maxHeight="100%"
                            />
                        )}
                    </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default PreviewImage;