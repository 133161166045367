import React, { useState } from 'react';
import "./style.css";
import CustomInputText from 'components/custom-input-text';
import CustomButton from 'components/custom-button';
import AuthenticationService from 'services/login/auth-service';
import { Box, Spinner } from '@chakra-ui/react';
import { Modal } from 'react-bootstrap';
import CustomNotification from 'components/custom-notification';

interface ForgotPasswordProps {
    isOpen: boolean;
    onClose: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            CustomNotification({
                title: 'Atenção!',
                description: 'Por favor, insira um e-mail válido.',
                notificationType: 'warning',
                placement: 'top-right',
            });
            return;
        }

        setLoading(true);
        const response = await AuthenticationService.ForgotPassword(email);
        setLoading(false);

        if (response) {
            setEmail('');
            CustomNotification({
                title: 'Ação concluída com sucesso!',
                description: 'Código de recuperação enviado com sucesso!',
                notificationType: 'success',
                placement: 'top-right'
            });
            
            onClose();
        } else {
            CustomNotification({
                title: 'Algo deu errado!',
                description: 'Erro ao enviar o código de recuperação. Tente novamente.',
                notificationType: 'error',
                placement: 'top-right'
            });
        }
    };

    return (
        <Modal 
            show={isOpen}
            onHide={onClose}
            className="container-modal-forgot-password"
            backdrop={loading ? 'static' : true}
            keyboard={!loading} 
            centered 
        >
            <Modal.Body>
                <div className="container-forgot-password">
                    {loading && (
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bg="rgba(255, 255, 255, 0.8)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            zIndex="10"
                        >
                            <Spinner size="xl" />
                        </Box>
                    )}           
                    <div className='title-forgot-password'>
                        <span>Recuperação de Senha</span>
                    </div>
                    <div className='description-forgot-password'>
                        <span>Informe o email da conta que deseja recuperar a senha e enviaremos um código de confirmação.</span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='container-field-forgot-password'>
                            <CustomInputText
                                label=''
                                placeholder='Digite seu e-mail...'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className='container-button-forgot-password'>
                            <CustomButton
                                labelText='Enviar código'
                                variant='primary'
                                type='submit'
                            />
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ForgotPassword;
