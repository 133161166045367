import React from 'react';
import "./style.css";

interface CustomInputCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    isChecked: boolean;
    onCheckboxChange: () => void;
}
  
const CustomInputCheckbox: React.FC<CustomInputCheckboxProps> = ({ label, isChecked, onCheckboxChange }) => {
    return (
        <div className="container-main-custom-checkbox">
            <input 
                type="checkbox" 
                checked={isChecked} 
                onChange={onCheckboxChange} 
                className="custom-checkbox-input"
            />
            <label className="custom-checkbox-label">
                {label}
            </label>
        </div>
    );
};

export default CustomInputCheckbox;
