import React, { useEffect, useState } from 'react';
import "./style.css";
import Sidebar from 'components/sidebar';
import Navbar from 'components/header';
import CustomButton from 'components/custom-button';
import { useNavigate } from 'react-router-dom';
import CustomTableCrud from 'components/custom-table-crud';
import editIcon from '../../assets/icon_edit.png'; 
import { PaginationDefaultRequest } from '../../types/response/api-types';
import PriorityService from 'services/priority-service';
import { PriorityViewModel } from '../../types/models/priority-view-model';
import CustomNotification from 'components/custom-notification';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function Priorities() {
    const [priorities, setPriorities] = useState<PriorityViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        fetchPriorities();
    }, [currentPage, pageSize]);

    const fetchPriorities = async () => {
        setLoading(true);
        const request: PaginationDefaultRequest = {
            SearchTerm: '',
            Query: { Page: currentPage, PageSize: pageSize },
        };

        try {
            const response = await PriorityService.GetPagedPriorities(request);
            handleResponse(response);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleResponse = (response: any) => {
        if (response.success) {
            setPriorities(response.data.items);
            setTotal(response.data.count);
        } else {
            showNotification('Erro!', response.message || 'Falha ao obter prioridades.', 'error');
        }
    };

    const handleError = (error: any) => {
        console.error(error);
        showNotification('Algo deu errado!', 'Ocorreu um erro ao processar a solicitação.', 'error');
    };

    const showNotification = (title: string, description: string, type: 'error' | 'success' | 'info' | 'warning') => {
        CustomNotification({ title, description, notificationType: type, placement: 'top-right' });
    };

    const handleCreate = () => {
        navigate('/Workspace/Priorities/Create');
    };

    const formatCheckboxStatus = (rowData: PriorityViewModel) => (
        rowData.status ? <CheckOutlined className='crud-status-icon-check' /> : <CloseOutlined className='crud-status-icon-close' />
    );

    const formatEditPriority = (rowData: PriorityViewModel) => (
        <div className='crud-icon-edit' onClick={() => navigate('/Workspace/Priorities/Edit', { state: { rowData } })}>
            <img src={editIcon} alt="Icone de editar" />
        </div>
    );

    const formatColor = (rowData: PriorityViewModel) => (
        <div className='container-main-tag-color'>
            <div className='container-tag-color' style={{ backgroundColor: rowData.hexadecimal }}>
                <span>{rowData.name}</span>
            </div>
        </div>
    );

    const columns = [
        {
            title: 'Ativo',
            render: formatCheckboxStatus,
            onHeaderCell: () => ({ style: { width: '15%' } }),
            onCell: () => ({ style: { width: '15%' } }),
        },
        {
            title: 'Nome e Cor',
            render: formatColor,
            onHeaderCell: () => ({ style: { width: '20%' } }),
            onCell: () => ({ style: { width: '20%' } }),
        },
        {
            title: 'Descrição das Prioridades',
            dataIndex: 'description',
            onHeaderCell: () => ({ style: { width: '50%' } }),
            onCell: () => ({ style: { width: '50%' } }),
        },
        {
            title: '',
            render: formatEditPriority,
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } }),
        },
    ];    

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    return (
        <div className='container-main-priority'>
            <Sidebar />
            <Navbar />
            <div className='container-content-priority'>
                <div className='container-header-priority'>
                    <span>Gerenciamento de Prioridades</span>
                    <div className='button-header-priority'>
                        <CustomButton variant='primary' labelText='Cadastrar nova Prioridade' onClick={handleCreate} />
                    </div>
                </div>

                <div>
                    <CustomTableCrud
                        columns={columns}
                        dataSource={priorities}
                        total={total}
                        loading={loading}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        title='Prioridades'
                        handleTableChange={handleTableChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default Priorities;