import { Color } from '../types/color-types';
import AuthenticationService from './login/auth-service';
import { ApiResponse } from '../types/response/api-types';
import Config from 'config';

const API_URL = `${Config.API_URL}${Config.Endpoints.Color}`;

const ColorService = {
    GetAll: async (): Promise<ApiResponse<Color>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getallcolors`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                }
            });

            const data: ApiResponse<Color> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as colores.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as colores.",
                data: [] 
            };
        }
    }
}

export default ColorService;