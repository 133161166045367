import React, { useEffect, useState } from 'react';
import "./style.css";
import Sidebar from 'components/sidebar';
import Navbar from 'components/header';
import CustomButton from 'components/custom-button';
import { useNavigate } from 'react-router-dom';
import CustomTableCrud from 'components/custom-table-crud';
import { ProfileType } from '../../types/profile-type-types';
import ProfileTypeService from 'services/profile-type-service';
import { PaginationDefaultRequest } from '../../types/response/api-types';
import editIcon from '../../assets/icon_edit.png'; 
import { Checkbox } from 'antd';
import CustomNotification from 'components/custom-notification';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function useProfileTypes(currentPage: number, pageSize: number) {
    const [profileTypes, setProfileTypes] = useState<ProfileType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        const fetchProfileTypes = async () => {
            setLoading(true);
            const request: PaginationDefaultRequest = {
                SearchTerm: '',
                Query: {
                    Page: currentPage,
                    PageSize: pageSize
                }
            };
            const response = await ProfileTypeService.GetPagedProfileTypes(request);
            if (response.success) {
                setProfileTypes(response.data.items);
                setTotal(response.data.count);
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: (response.message || 'Falha ao obter os Tipos de Perfis.'),
                    notificationType: 'error',
                    placement: 'top-right'
                });
                setError(response.message || 'Falha ao obter os Tipos de Perfis.');
            }
            setLoading(false);
        };

        const timeoutId = setTimeout(fetchProfileTypes, 300);
        return () => clearTimeout(timeoutId);
    }, [currentPage, pageSize]);

    return { profileTypes, loading, error, total };
}

const CheckboxStatus = ({ status }: { status: boolean }) => (
    <Checkbox disabled={true} checked={status} className='container-custom-checkbox-input' />
);

const EditButton = ({ onClick }: { onClick: () => void }) => (
    <div className='crud-icon-edit' onClick={onClick}>
        <img src={editIcon} alt="Icone de editar" />
    </div>
);

function ProfileTypes() {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const navigate = useNavigate();

    const { profileTypes, loading, error, total } = useProfileTypes(currentPage, pageSize);
    
    const handleCreate = () => {
        navigate('/Workspace/ProfileTypes/Create');
    };

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const formatCheckboxStatus = (rowData: ProfileType) => (
        rowData.status ? <CheckOutlined className='crud-status-icon-check' /> : <CloseOutlined className='crud-status-icon-close' />
    );

    const handleClick = (rowData: ProfileType) => {
        navigate('/Workspace/ProfileTypes/Edit', { state: { rowData } });
    };

    const formatEditModule = (rowData: ProfileType) => (
        <div className='crud-icon-edit' onClick={() => handleClick(rowData)}>
            <img src={editIcon} alt="Ícone de editar" />
        </div>
    );

    const columns = [
        {
            title: 'Ativo',
            render: (rowData: ProfileType) => formatCheckboxStatus(rowData),
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } })
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            onHeaderCell: () => ({ style: { width: '30%' } }),
            onCell: () => ({ style: { width: '30%' } })
        },
        {
            title: 'Descrição do Tipo de Perfil',
            dataIndex: 'description',
            onHeaderCell: () => ({ style: { width: '40%' } }),
            onCell: () => ({ style: { width: '40%' } })
        },
        {
            title: '',
            render: formatEditModule,
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } })
        }
    ];    

    return (
        <div className='container-main-profile-type'>
            <Sidebar />
            <Navbar />
            <div className='container-content-profile-type'>
                <div className='container-header-profile-type'>
                    <span>Tipos de Perfis</span>
                    <div className='button-header-profile-type'>
                        <CustomButton variant='primary' labelText='Cadastrar novo Perfil' onClick={handleCreate} />
                    </div>
                </div>
                
                <CustomTableCrud
                    columns={columns}
                    dataSource={profileTypes}
                    total={total}
                    loading={loading}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    title='Tipos de Perfis'
                    handleTableChange={handleTableChange}
                />
            </div>
        </div>
    );
}

export default ProfileTypes;