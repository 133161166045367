import Config from "config";
import AuthenticationService from "./login/auth-service";
import { ApiResponse, FilterDashboardQuery, GetAverageTicketsCompletionDashboardResponse, GetProfileTypesDashboardResponse } from "../types/response/api-types";
import { TicketFeedback } from "../types/ticket-feedback-types";
import { PriorityCount } from "../types/priority-count-types";

const API_URL = `${Config.API_URL}${Config.Endpoints.Dashboard}`;

const DashboardService = {
    GetProfileTypesDashboard: async (filter?: FilterDashboardQuery): Promise<ApiResponse<GetProfileTypesDashboardResponse>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getprofiletypesdashboard`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ Filter: filter})
            });

            const data: ApiResponse<GetProfileTypesDashboardResponse> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as informações para dashboard contagem de tipo de perfil.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as informações para dashboard contagem de tipo de perfil.",
                data: [] 
            };
        }
    },
    GetTicketFeedbacksDashboard: async (filter?: FilterDashboardQuery): Promise<ApiResponse<TicketFeedback>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getticketfeedbacksdashboard`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ Filter: filter})
            });

            const data: ApiResponse<TicketFeedback> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as informações para dashboard feedback.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as informações para dashboard feedback.",
                data: [] 
            };
        }
    },
    GetQuantityTicketsDashboard: async (filter?: FilterDashboardQuery): Promise<ApiResponse<PriorityCount>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getquantityticketsdashboard`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ Filter: filter})
            });

            const data: ApiResponse<PriorityCount> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as informações das quantidades de chamados.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as informações das quantidades de chamados.",
                data: [] 
            };
        }
    },
    GetAverageTicketsCompletionDashboard: async (filter?: FilterDashboardQuery): Promise<ApiResponse<GetAverageTicketsCompletionDashboardResponse>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getaverageticketscompletiondashboard`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ Filter: filter})
            });

            const data: ApiResponse<GetAverageTicketsCompletionDashboardResponse> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as informações do tempo médio dos chamados.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as informações do tempo médio dos chamados.",
                data: [] 
            };
        }
    }
}

export default DashboardService;