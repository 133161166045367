import { UserRole } from '../../types/enum/user-role-types';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { DecodeToken } from 'utils/decode-token';

interface PublicRouteProps {
  element: React.ReactElement;
  path: string;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
    const token = localStorage.getItem('workflow-platform-token-auth');
    const location = useLocation();
    const navigate = useNavigate(); 

    useEffect(() => {
        if (token) {
            const claims = DecodeToken(token);
            if (claims && claims.exp && claims.exp > Date.now() / 1000) {
                const profile = claims.profile.toLowerCase();

                if (profile === UserRole.Administrator.toLowerCase()) {
                    console.log("Entrou aqui no adm");
                    navigate('/Workspace', { state: { from: location } });
                } else if (profile === UserRole.Operator.toLowerCase()) {
                    navigate('/Manage', { state: { from: location } });
                } else {
                    navigate('/Tickets', { state: { from: location } });
                }
            }
        }
    }, [token, navigate, location]);

    return element; 
};

export default PublicRoute;